import { cva } from 'class-variance-authority';

export const main = cva([
  'sm:min-w-[384px]',
  'flex',
  'flex-col',
  'gap-2',
  'justify-between',
  'font-inter',
  'h-full',
]);

export const title = cva([
  'text-3xl',
  'font-semibold',
  'text-neutral-900',
  'mb-2',
]);

export const subTitle = cva([
  'text-lg',
  'sm:text-base',
  'font-light',
  'text-neutral-700',
]);

export const header = cva(['w-full']);

export const form = cva([
  'w-full',
  'max-w-[383.99px]',
  'flex',
  'flex-col',
  'gap-8',
]);

export const logo = cva(['mb-6', 'mt-1', 'ml-1', 'w-full', 'max-w-[376px]']);

export const footer = cva([
  'flex',
  'flex-col',
  'items-center',
  'gap-4',
  'justify-end',
]);

export const privacyPolicy = cva(['text-sm', 'text-center', 'text-brand-400']);

export const copyright = cva(['text-xs', 'text-neutral-500', 'text-center']);

export const icon = cva(['text-brand-900', 'w-4', 'h-4', 'mt-1']);

export const link = cva([
  'flex',
  'gap-4',
  'items-center',
  'self-center',
  'text-brand-400',
  'text-sm',
  'font-normal',
]);

export const back = cva(['text-brand-500', 'h-4', 'w-4']);

export const button = cva(['w-full', 'h-16']);
