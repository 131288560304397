import { RouteObject } from 'react-router-dom';

import ErrorBoundary from '@/common/components/ErrorBoundary';
import { links } from '@/common/utils/links';
import { LoginLayout } from '@/layouts/Login';

import AccountSelection from '../pages/AccountSelection';
import ActivateUser from '../pages/ActivateUser';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import SignIn from '../pages/SignIn';
import StoreSelection from '../pages/StoreSelection';

export const LoginRoutes: RouteObject[] = [
  {
    element: <LoginLayout />,
    children: [
      {
        path: links.login.main,
        element: <SignIn />,
      },
      {
        path: links.login.forgotPassword,
        element: <ForgotPassword />,
      },
      {
        path: links.login.resetPassword,
        element: <ResetPassword />,
      },
      {
        path: links.login.activateUser,
        element: <ActivateUser />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: links.login.storeSelection,
    element: <StoreSelection />,
  },
  {
    path: links.login.accountSelection,
    element: <AccountSelection />,
  },
  {
    path: '*',
    element: <LoginLayout />,
    children: [
      {
        path: '*',
        index: true,
        element: <SignIn />,
      },
    ],
  },
];
