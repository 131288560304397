import { Home } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { links } from '@/common/utils/links';

import Button from '../Button';

import * as style from './styles';

export default function NotFoundComponent() {
  const { t } = useTranslation('common');

  return (
    <div className={style.container()}>
      <div className={style.content()}>
        <main className={style.main()}>
          <span className={style.status()}>
            {t('defaultErrorTitle')}
            <br />
            {t('defaultErrorName')} 404
          </span>

          <div className={style.textWrapper()}>
            <p className={style.textError()}>{`${t('anErrorOccurred')}.`}</p>
          </div>
          <div className={style.buttons()}>
            <Link to={links.panel} className={style.wrapperButton()}>
              <Button
                text={t('backToHome')}
                leadingIcon={<Home className={style.homeIcon()} />}
                className={style.buttonPrimary()}
              />
            </Link>
          </div>
        </main>
      </div>
    </div>
  );
}
