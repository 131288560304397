import { IMainDeal } from '@/@types/Deal';
import { IPlanRanking } from '@/@types/Plan';
import { UserRoleEnum, UserTypeEnum } from '@/@types/User';
import { IVehicleRanking } from '@/@types/Vehicle';

import { httpClient } from '@/config/httpClient';

export type IDashboardKPIs = {
  generatedDeals: {
    quantity: number;
    totalAmount: number;
    quantityOfProducts: number;
  };
  winnerDeals: {
    quantity: number;
    totalAmount: number;
    conversionRate: number;
    totalAmmountConversionRate: number;
  };
  lostDeals: {
    quantity: number;
    totalAmount: number;
    conversionRate: number;
    totalAmmountConversionRate: number;
  };
  discountedDeals: {
    quantity: number;
    totalAmount: number;
    conversionRate: number;
    totalAmmountConversionRate: number;
  };
  avgContractTimeInMonths: number;
  avgClosingTimeInDays: number;
  avgWinnerDealAmount: number;
  mainDeals: IMainDeal[];
  planRanking: IPlanRanking[];
  vehicleRanking: IVehicleRanking[];
};

export async function getDashboardKPIs(
  userType: string,
  role: string,
  userSellerId: string,
  storeId?: string | null,
  dealerId?: string,
  accountId?: string | null,
  freeSearch?: string,
  startDate?: string | null,
  endDate?: string | null,
  tagsSearch?: string,
  storeSearch?: string,
  sellerSearch?: string,
  productSearch?: string,
  locality?: string,
) {
  const where: Record<string, any> = {
    isActive: true,
  };

  if (
    freeSearch ||
    storeSearch ||
    tagsSearch ||
    sellerSearch ||
    productSearch ||
    locality
  ) {
    Object.assign(where, { AND: [] });
  }

  if (accountId) {
    Object.assign(where, { accountId });
  }

  if (!userType) {
    Object.assign(where, { id: new Date().getTime().toString() });
  }

  if (freeSearch) {
    const freeSearchWhere: Record<string, object[]> = { OR: [] };

    freeSearchWhere.OR.push({ title: { contains: freeSearch } });
    freeSearchWhere.OR.push({ dealCode: { contains: freeSearch } });
    freeSearchWhere.OR.push({ Customer: { name: { contains: freeSearch } } });
    freeSearchWhere.OR.push({
      Customer: { socialName: { contains: freeSearch } },
    });
    freeSearchWhere.OR.push({
      Customer: { documentNumber: { contains: freeSearch } },
    });

    console.log('freeSearchWhere', freeSearchWhere);

    where.AND.push(freeSearchWhere);
  }

  if (sellerSearch) {
    const sellerSearchWhere: Record<string, object[]> = { OR: [] };

    sellerSearchWhere.OR.push({ User: { email: { contains: sellerSearch } } });
    sellerSearchWhere.OR.push({
      User: { displayName: { contains: sellerSearch } },
    });

    where.AND.push(sellerSearchWhere);
  }

  if (storeSearch) {
    const storeSearchWhere: Record<string, object[]> = { OR: [] };

    storeSearchWhere.OR.push({ Store: { name: { contains: storeSearch } } });
    storeSearchWhere.OR.push({
      Store: { displayName: { contains: storeSearch } },
    });
    storeSearchWhere.OR.push({
      Store: { documentNumber: { contains: storeSearch } },
    });
    storeSearchWhere.OR.push({
      Store: { subsidiaryCode: { contains: storeSearch } },
    });
    storeSearchWhere.OR.push({
      Store: { Dealer: { headquartersCode: { contains: storeSearch } } },
    });

    where.AND.push(storeSearchWhere);
  }

  if (tagsSearch) {
    Object.assign(where, { tags: { array_contains: tagsSearch } });
  }

  if (locality) {
    const localityWhere: Record<string, object[]> = { OR: [] };

    localityWhere.OR.push({ Store: { stateAcronym: { contains: locality } } });
    localityWhere.OR.push({ Store: { city: { contains: locality } } });
    localityWhere.OR.push({
      Store: { tags: { array_contains: locality } },
    });

    where.AND.push(localityWhere);
  }

  if (productSearch) {
    const productSearchWhere: Record<string, object[]> = { OR: [] };

    productSearchWhere.OR.push({
      DealProduct: {
        some: {
          ProductModel: {
            title: { contains: productSearch },
          },
        },
      },
    });
    productSearchWhere.OR.push({
      DealProduct: {
        some: {
          ProductModel: {
            Product: { title: { contains: productSearch } },
          },
        },
      },
    });

    where.AND.push(productSearchWhere);
  }

  if (userType === UserTypeEnum.DEALER) {
    Object.assign(where, { Store: { dealerId } });
    if (storeId) {
      Object.assign(where, { storeId });
    }
    if (role === UserRoleEnum.SELLER) {
      Object.assign(where, { userSellerId });
    }
  }

  if (startDate && endDate) {
    Object.assign(where, {
      createdAt: {
        gte: new Date(startDate).toISOString(),
        lte: new Date(endDate).toISOString(),
      },
    });
  }

  return httpClient.get<IDashboardKPIs>('/dashboard/deal-kpis', {
    params: {
      relations: {
        where,
      },
    },
  });
}
