import { useDebounce } from '@uidotdev/usehooks';
import { ReactNode, TextareaHTMLAttributes, useEffect } from 'react';
import { FieldErrors, FieldValues, useFormContext } from 'react-hook-form';

import { classMerge } from '@/common/utils';

import { Spinner } from '../Spinner';

import * as style from './styles';

function getErrorMessage(errors: FieldErrors<FieldValues>, fieldName: string) {
  const splittedName = fieldName.split('.');
  let currentLevel = errors;

  // eslint-disable-next-line no-restricted-syntax
  for (const propertyName of splittedName) {
    if (currentLevel && currentLevel[propertyName]) {
      currentLevel = currentLevel[propertyName] as FieldErrors<FieldValues>;
    }
  }

  return currentLevel.message as unknown as string;
}

type TextAreaProps = {
  size?: style.TextAreaVariants['size'];
  name: string;
  label?: string;
  labelClassName?: string;
  LeadingIcon?: ReactNode;
  leadingIconClassName?: string;
  TrailingIcon?: ReactNode;
  handleClickTrailingIcon?: () => void;
  isDirty?: boolean;
  isErrored?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
  debounce?: {
    fn: (value: string) => void;
    ms: number;
  };
  noPaste?: boolean;
  cols?: number;
} & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'size'>;

export function TextArea({
  cols = 40,
  size = 'md',
  name,
  label,
  labelClassName,
  LeadingIcon,
  TrailingIcon,
  handleClickTrailingIcon,
  isDirty,
  isErrored,
  errorMessage,
  debounce,
  isLoading,
  noPaste,
  ...props
}: TextAreaProps) {
  const { register, formState, watch } = useFormContext();

  const { errors, dirtyFields } = formState;

  const hasError = isErrored || !!getErrorMessage(errors, name);

  const isSuccessful = Boolean(
    !hasError && (isDirty || dirtyFields[name]) && watch(name),
  );
  const debouncedValue = useDebounce(watch(name), debounce?.ms || 0);

  useEffect(() => {
    if (debouncedValue) {
      debounce?.fn(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <div className="w-full text-start">
      <div className="relative">
        {LeadingIcon && (
          <div
            className={style.ContainerIcon({
              leading: true,
              isLabelled: !!label,
            })}
          >
            {LeadingIcon}
          </div>
        )}

        {label && (
          <label
            htmlFor={name}
            className={classMerge(style.Label(), labelClassName)}
          >
            {label}
          </label>
        )}

        <textarea
          id={name}
          cols={cols}
          className={style.Input({
            hasError: !!errorMessage || isErrored || hasError,
            isSuccessful: isSuccessful && !isLoading,
            hasLeadingIcon: !!LeadingIcon,
            hasTrailingIcon: !!TrailingIcon,
            size,
            isDisabled: props.disabled || isLoading,
          })}
          disabled={props.disabled || isLoading}
          {...register(name)}
          onDrop={noPaste ? e => e.preventDefault() : undefined}
          onPaste={noPaste ? e => e.preventDefault() : undefined}
          {...props}
        ></textarea>

        {TrailingIcon && (
          <div
            className={style.ContainerIcon({
              leading: false,
              isLabelled: !!label,
            })}
            onClick={handleClickTrailingIcon}
            onKeyDown={handleClickTrailingIcon}
            role="button"
            tabIndex={0}
          >
            {TrailingIcon}
          </div>
        )}

        {isLoading && (
          <div
            className={style.ContainerIcon({
              leading: false,
              isLabelled: !!label,
              isDisabled: props.disabled || isLoading,
            })}
          >
            <Spinner />
          </div>
        )}
      </div>

      {(hasError || !!errorMessage) && (
        <p className={style.ErrorMessage()}>
          {getErrorMessage(errors, name) || errorMessage}
        </p>
      )}
    </div>
  );
}
