import { cva } from 'class-variance-authority';

export const popoverContent = cva([
  'z-50',
  'w-72',
  'rounded-xl',
  'mt-2',
  'border',
  'border-neutral-200',
  'bg-white',
  'px-4',
  'text-neutral-950',
  'shadow-default',
  'outline-none',
  'data-[state=open]:animate-in',
  'data-[state=closed]:animate-out',
  'data-[state=closed]:fade-out-0',
  'data-[state=open]:fade-in-0',
  'data-[state=closed]:zoom-out-95',
  'data-[state=open]:zoom-in-95',
  'data-[side=bottom]:slide-in-from-top-2',
  'data-[side=left]:slide-in-from-right-2',
  'data-[side=right]:slide-in-from-left-2',
  'data-[side=top]:slide-in-from-bottom-2',
]);
