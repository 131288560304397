import { IMainDeal } from '@/@types/Deal';
import { useFlags } from 'flagsmith/react';
import { GripVertical, Receipt } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { useToast } from '@/common/hooks/useToast';
import { formatCurrency } from '@/common/utils/formatCurrency';

import { Tooltip } from '../Tooltip';

import * as style from './styles';

type MainDealsProps = {
  deals: IMainDeal[];
};

export function MainDeals({ deals }: MainDealsProps) {
  const { t } = useTranslation('panel/panel');
  const { toast } = useToast();
  const { enabled_swapy_dashboard } = useFlags(['enabled_swapy_dashboard']);

  const handleCopyDealCode = (dealCode: string) => {
    navigator.clipboard.writeText(dealCode);
    toast.info(t('dealCodeCopied'));
  };

  return (
    <div className={style.container()} data-swapy-item="mainDeals">
      {enabled_swapy_dashboard.enabled && (
        <div data-swapy-handle className={style.handle()}>
          <GripVertical className={style.handleIcon()} />
        </div>
      )}

      <div className={style.titleContainer()}>
        <Receipt className={style.icon()} />

        <p className={style.title()}>{t('mainDeals')}</p>
      </div>

      <ul className={style.deals()}>
        {deals.map((deal, index) => (
          <li className={style.deal()} key={deal.dealCode}>
            <div className={style.dealer()}>
              <span className={style.dealerPosition()}>{index + 1}º</span>

              <div className={style.dealerInfo()}>
                <span className={style.storeName()}>{deal.storeName}</span>

                <span className={style.subData()}>
                  <Tooltip message={t('copyCode')}>
                    <button onClick={() => handleCopyDealCode(deal.dealCode)}>
                      {deal.dealCode}
                    </button>
                  </Tooltip>
                </span>

                <span className={style.subData()}>{deal.sellerName}</span>
              </div>
            </div>

            <div className={style.values()}>
              <span className={style.total()}>
                {t('total')} <b>{formatCurrency(deal.totalDealAmount)}</b>
              </span>

              <span className={style.installment()}>
                {t('installment')}{' '}
                <b>{formatCurrency(deal.monthlyInstallmentPrice)}</b>
              </span>

              <span className={style.installment()}>
                {t('totalProducts')} <b>{deal.totalQuantityOfProducts}</b>
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
