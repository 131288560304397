import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IBatchDebitNotePaginated } from '@/modules/invoicing/services/getPaginateBatchDebitNote';

import * as styles from './styles';

type TableHeadProps = {
  data: IBatchDebitNotePaginated[];
  selectedBatchs: IBatchDebitNotePaginated[];
  onSelectAllRows: () => void;
  disableCheckbox: boolean;
};

export function TableHead({
  data,
  selectedBatchs,
  onSelectAllRows,
  disableCheckbox,
}: TableHeadProps) {
  const { t } = useTranslation(['invoicing/openInvoices', 'common']);

  const columns = [
    {
      field: 'details',
      label: t('common:details'),
    },
    {
      field: 'invoice',
      label: t('invoice'),
    },
    {
      field: 'value',
      label: t('value'),
    },
    {
      field: 'situation',
      label: t('situation'),
    },
    {
      field: 'actions',
      label: t('common:actions'),
    },
  ];

  useLayoutEffect(() => {
    const checkBoxSelectAll = document.getElementById(
      'hs-table-pagination-checkbox-all',
    ) as HTMLInputElement;

    if (selectedBatchs.length && selectedBatchs.length !== data?.length) {
      checkBoxSelectAll.indeterminate = true;
    }

    if (selectedBatchs.length === data.length && selectedBatchs.length) {
      checkBoxSelectAll.indeterminate = false;
    }
  }, [selectedBatchs, data]);

  return (
    <thead className={styles.TableHead()}>
      <tr>
        <th scope="col" className={styles.TableHeadCheckbox()}>
          <div className={styles.ContainerCheckbox()}>
            <input
              id="hs-table-pagination-checkbox-all"
              type="checkbox"
              className={styles.Checkbox()}
              onChange={onSelectAllRows}
              checked={
                selectedBatchs.length === data.length &&
                selectedBatchs.length > 0
              }
              disabled={disableCheckbox}
            />

            <label
              htmlFor="hs-table-pagination-checkbox-all"
              className="sr-only"
            >
              Checkbox
            </label>
          </div>
        </th>

        {columns.map((column, index) => (
          <th
            key={column.field}
            scope="col"
            className={styles.Column({ position: index })}
          >
            {column.label}
          </th>
        ))}
      </tr>
    </thead>
  );
}
