import { subDays } from 'date-fns';
import { create } from 'zustand';

import { DashboardFilterForm } from '../schemas/DashboardFilterSchema';

type DashboardFiltersState = {
  freeSearch?: string;
  storeSearch?: string;
  tagsSearch?: string;
  sellerSearch?: string;
  productSearch?: string;
  startDate: string | null;
  endDate: string | null;
  locality?: string;
};

type DashboardFiltersActions = {
  updateDashboardFilters: (data: Partial<DashboardFilterForm>) => void;
  resetDashboardFilters: () => void;
  resetDashboardFreeSearch: () => void;
};

type DashboardFilters = DashboardFiltersState & DashboardFiltersActions;

export const useDashboardFilters = create<DashboardFilters>(set => {
  return {
    freeSearch: '',
    storeSearch: '',
    tagsSearch: '',
    sellerSearch: '',
    productSearch: '',
    startDate: subDays(new Date(), 30).toISOString(),
    endDate: new Date().toISOString(),
    locality: '',
    updateDashboardFilters(filters: Partial<DashboardFilterForm>) {
      set({ ...filters });
    },
    resetDashboardFilters() {
      set({
        freeSearch: '',
        storeSearch: '',
        tagsSearch: '',
        sellerSearch: '',
        productSearch: '',
        startDate: undefined,
        endDate: undefined,
        locality: '',
      });
    },
    resetDashboardFreeSearch() {
      set({ freeSearch: '' });
    },
  };
});
