import { cva } from 'class-variance-authority';

export const container = cva(['flex w-full']);

export const tableDataCheckbox = cva(['py-3', 'pl-4']);

export const containerCheckbox = cva(['flex', 'items-center', 'h-5']);

export const checkbox = cva([
  'border-gray-200',
  'rounded',
  'text-blue-600',
  'focus:ring-blue-500',
  'cursor-pointer',
  'disabled:opacity-50 disabled:cursor-not-allowed',
]);

export const tableData = cva(
  [
    'px-6',
    'py-3',
    'whitespace-nowrap',
    'text-sm',
    'font-medium',
    'text-neutral-800',
  ],
  {
    variants: {
      position: {
        0: 'w-3/12',
        1: 'w-3/12',
        2: 'w-3/12',
        3: 'w-2/12',
      },
    },
  },
);

export const tableSubData = cva([
  'block',
  'text-sm',
  'text-neutral-500',
  'font-light',
]);

export const rowActions = cva([
  'w-1/12',
  'py-4',
  'pr-7',
  'flex',
  'justify-end',
  'gap-2',
  'text-brand-pure',
]);

export const buttonItem = cva(['flex', 'items-end', 'justify-center', 'gap-2']);

export const formComment = cva([
  'ml-12 w-3/12 pb-2',
  'flex items-center justify-center gap-2',
]);
