import { useSelectedDebitNote } from '@/modules/invoicing/hooks/useSelectedDebitNote';

import { TableHead } from './components/TableHead';
import { TableRow } from './components/TableRow';
import * as style from './styles';

export function ChassisTable() {
  const { selectedDebitNote } = useSelectedDebitNote();

  return (
    <table className={style.table()}>
      <TableHead />

      <tbody className={style.tableBody()}>
        {selectedDebitNote?.DebitNoteSignChassisInstallments.map(
          installment => (
            <TableRow key={installment.id} data={installment} />
          ),
        )}
      </tbody>
    </table>
  );
}
