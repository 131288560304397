import flagsmith from 'flagsmith';
import Cookies from 'js-cookie';
import { LayoutGrid } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { CardApp } from '@/common/components/CardApp';
import { Modal } from '@/common/components/Modal';
import { SaveUserAccountProps, useUserStore } from '@/common/hooks/UserStore';
import { useToast } from '@/common/hooks/useToast';

import * as style from './styles';

export function AccountSelectionModal() {
  const { t } = useTranslation(['login/accountSelection', 'common']);
  const {
    saveUserAccount,
    userAccounts,
    accountId: currentAccountId,
  } = useUserStore();
  const { toast } = useToast();

  const handleLoadUserAccount = async (
    selectedAccount: SaveUserAccountProps,
  ) => {
    const { accountId, role } = selectedAccount;

    Cookies.set('accountId', accountId);
    Cookies.set('role', role);
    await flagsmith.setTrait('role', role);

    saveUserAccount({
      accountId,
      role,
    });
    window.HSOverlay.close(
      document.getElementById('hs-system-accounts-modal')!,
    );
    toast.success(t('common:success'), t('storeChanged'));
  };

  return (
    <Modal
      id="system-accounts-modal"
      title={t('changeStoreAccess')}
      titleIcon={<LayoutGrid size={24} className={style.icon()} />}
      dispensable
      subTitle={
        <p className={style.subTitle()}>
          {t('selectStore')} <strong className={style.nexpro()}>QTOOL</strong>.
        </p>
      }
    >
      <div className={style.content()}>
        <section className={style.section()}>
          <ul className={style.list()}>
            {userAccounts?.map(account => (
              <li key={account.accountId}>
                <CardApp
                  account={account.Account}
                  onClick={() =>
                    handleLoadUserAccount({
                      accountId: account.accountId,
                      role: account.role,
                    })
                  }
                  isSelected={account.accountId === currentAccountId}
                />
              </li>
            ))}
          </ul>
        </section>
      </div>
    </Modal>
  );
}
