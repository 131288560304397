import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { I18nextProvider } from 'react-i18next';

import { ToastContainer } from './common/components/Toast';
import { AppRouterProvider } from './common/hooks/useAppRouter';
import { ENV } from './config/env';
import i18n from './config/i18n';
import { queryClient } from './config/queryClient';
import { AppRoutes } from './routes/index.routes';

const cache = createCache({
  key: 'emotion-cache-key',
  nonce: 'react-select',
});

function App() {
  return (
    <CacheProvider value={cache}>
      <I18nextProvider i18n={i18n}>
        <FlagsmithProvider
          options={{
            environmentID: ENV.VITE_FLAGSMITH_ENV_ID,
            cacheFlags: true,
          }}
          flagsmith={flagsmith}
        >
          <QueryClientProvider client={queryClient}>
            <AppRouterProvider>
              <AppRoutes />
              <ToastContainer />
            </AppRouterProvider>
            <ReactQueryDevtools initialIsOpen={false} position="top-left" />
          </QueryClientProvider>
        </FlagsmithProvider>
      </I18nextProvider>
    </CacheProvider>
  );
}

export default App;
