import { cva } from 'class-variance-authority';

export const button = cva([
  'flex',
  'flex-col',
  'justify-center',
  'w-full',
  'text-neutral-800',
  'font-semibold',
  'text-sm',
]);

export const label = cva([
  'inline-block',
  'text-sm',
  'font-semibold',
  'mb-2.5',
]);

export const calendarIcon = cva(['text-brand-900', 'h-4', 'w-4'], {
  variants: {
    isDisabled: {
      true: ['cursor-text', 'bg-gray-100', 'text-gray-500'],
    },
  },
});

export const trashIcon = cva([
  'text-driver-centricity-800',
  'h-4',
  'w-4',
  'ml-auto',
]);

export const fakeInput = cva(
  [
    'flex',
    'items-center',
    'gap-3',
    'w-full',
    'rounded-md',
    'border-2',
    'border-gray-200',
    'focus:border-blue-500',
    'focus:ring-blue-500',
    'text-sm',
    'font-normal',
    'placeholder-neutral-500',
    'font-medium',
    'bg-white',
  ],
  {
    variants: {
      hasError: {
        true: ['border-red-500', 'focus:border-red-500', 'focus:ring-red-500'],
      },
      isSuccessful: {
        true: [
          'border-teal-500',
          'focus:border-teal-500',
          'focus:ring-teal-500',
        ],
      },
      size: {
        sm: ['py-2', 'px-3'],
        md: ['py-3', 'px-4'],
        lg: ['py-3', 'px-4', 'sm:p-5'],
      },
      isDisabled: {
        true: ['cursor-not-allowed', '!bg-gray-100', '!text-gray-500'],
      },
    },
    defaultVariants: {
      hasError: false,
      isSuccessful: false,
      size: 'md',
    },
  },
);

export const placeholder = cva(['text-neutral-500']);
