import { cva } from 'class-variance-authority';

export const tabsList = cva([
  'inline-flex',
  'h-12',
  'justify-between',
  'items-center',
  'text-neutral-500',
  'border-b-1',
  'border-b-neutral-200',
  'w-full',
]);

export const tabsTrigger = cva([
  'flex',
  'items-center',
  'justify-center',
  'font-inter',
  'font-medium',
  'whitespace-nowrap',
  'text-neutral-700',
  'border-b-2',
  'border-b-neutral-200',
  'text-sm',
  'py-[14px]',
  'px-2',
  'transition-all',
  'focus-visible:outline-none',
  'disabled:pointer-events-none',
  'disabled:opacity-50',
  'data-[state=active]:text-brand-500',
  'data-[state=active]:border-b-2',
  'data-[state=active]:border-b-brand-500',
]);

export const tabsContent = cva(['mt-2']);
