import { useUserStore } from '../hooks/UserStore';

export function maskCompanyDocument(data: string) {
  if (!data) return data;

  const { userAccount } = useUserStore.getState();

  if (!userAccount?.Account?.country) return data;

  const masks: Record<string, [RegExp, string]> = {
    BR: [/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'],
    AR: [/^(\d{2})(\d{8})(\d{1})/, '$1-$2-$3'],
    US: [/^(\d{2})(\d{7})/, '$1-$2'],
    CO: [/^(\d{9})(\d{1})/, '$1-$2'],
    EC: [/^(\d{11})/, '$1'],
    IT: [/^(\d{11})/, '$1'],
  };

  const mask = masks[userAccount.Account.country || 'BR'];

  return data.replace(mask[0], mask[1]);
}
