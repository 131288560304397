import { cva } from 'class-variance-authority';

export const container = cva([
  'w-full',
  'py-4',
  'px-6',
  'rounded-md',
  'items-center',
  'text-center',
  'justify-center',
  'flex',
  'flex-col',
  'min-h-[140px]',
]);

export const title = cva(['text-neutral-700', 'text-lg', 'font-semibold']);
export const subtitle = cva(['text-neutral-600', 'text-sm', 'font-normal']);
