import { IAxiosError } from '@/@types/AxiosError';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useToast } from '@/common/hooks/useToast';

import { postRemoveDebitNotes } from '../services/postRemoveDebitNotes';

export function useRequestApprovalRemoveDebitNotes() {
  const { t } = useTranslation('invoicing/requestApprovalReview');
  const { toast } = useToast();

  const { mutateAsync, isLoading } = useMutation({
    mutationKey: ['remove-debit-notes'],
    mutationFn: postRemoveDebitNotes,
    onSuccess: () => toast.success(t('successRemovedDebitNotes')),
    onError: (errorRequest: IAxiosError) => {
      toast.error(
        t('errorOnUnlinkDebitNotes'),
        errorRequest?.response?.data?.friendlyMessage,
        {
          toastId: 'unlink-debit-notes',
        },
      );
    },
  });

  return {
    removeDebitNotes: mutateAsync,
    isRemovingDebitNotes: isLoading,
  };
}
