import { zodResolver } from '@hookform/resolvers/zod';
import { jwtDecode } from 'jwt-decode';
import { Eye, EyeOff } from 'lucide-react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import QToolLogo from '@/assets/icons/qtool.svg';
import Button from '@/common/components/Button';
import { TextInput } from '@/common/components/TextInput';
import { useToast } from '@/common/hooks/useToast';
import { ENV } from '@/config/env';
import i18n from '@/config/i18n';

import { useResetPassword } from '../../api/useResetPassword';
import { useLogin } from '../../hooks/useLogin';
import {
  ResetPasswordForm,
  ResetPasswordSchema,
} from '../../schemas/ResetPasswordSchema';

import * as style from './styles';

type JwtDecoded = {
  email: string;
  userId: string;
  locale: string;
  expirationDate: string;
};

export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { toast } = useToast();
  const { t } = useTranslation(['login/resetPassword', 'common']);
  const { search } = useLocation();
  const { signOut } = useLogin();
  const params = new URLSearchParams(search);
  const verifyToken = params.get('verify-token') as string;
  const { resetPassword, isLoadingResetPasswordPassword } = useResetPassword();
  const hookForm = useForm<ResetPasswordForm>({
    mode: 'onBlur',
    resolver: zodResolver(ResetPasswordSchema()),
  });

  const handleToggleShowPassword = () => setShowPassword(!showPassword);

  const handleToggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleSubmitForgotPassword = async (data: ResetPasswordForm) => {
    try {
      const payload = {
        password: data?.password,
      };

      await resetPassword(payload, verifyToken);

      toast.success(t('passwordCreatedSuccessfully'));

      setTimeout(() => {
        signOut();
      }, 3000);
    } catch (error) {
      toast.error(`${t('unableToCreatePassword')}.`);
    }
  };

  useEffect(() => {
    if (!verifyToken) return;
    const decoded = jwtDecode<JwtDecoded>(verifyToken);

    i18n.changeLanguage(decoded.locale);
  }, [verifyToken]);

  return (
    <main className={style.main()}>
      <header className={style.header()}>
        <img src={QToolLogo} alt="QTool" className={style.logo()} />
      </header>

      <FormProvider {...hookForm}>
        <form
          className={style.form()}
          onSubmit={hookForm.handleSubmit(handleSubmitForgotPassword)}
        >
          <fieldset className={style.fieldset()}>
            <h1 className={style.title()}>{`${t('title')}`}</h1>

            <TextInput
              name="password"
              placeholder={t('newPassword')}
              type={showPassword ? 'text' : 'password'}
              TrailingIcon={
                showPassword ? (
                  <Eye className="text-neutral-500" size={16} />
                ) : (
                  <EyeOff className="text-neutral-500" size={16} />
                )
              }
              handleClickTrailingIcon={handleToggleShowPassword}
            />

            <TextInput
              name="confirmPassword"
              placeholder={t('confirmTheNewPassword')}
              type={showConfirmPassword ? 'text' : 'password'}
              TrailingIcon={
                showConfirmPassword ? (
                  <Eye className="text-neutral-500" size={16} />
                ) : (
                  <EyeOff className="text-neutral-500" size={16} />
                )
              }
              handleClickTrailingIcon={handleToggleShowConfirmPassword}
            />
          </fieldset>

          <fieldset className={style.fieldset()}>
            <Button
              type="submit"
              isLoading={isLoadingResetPasswordPassword}
              text={t('updatePassword')}
              className={style.button()}
            />

            <Link to="/" className={style.link()}>
              {t('cancel')}
            </Link>
          </fieldset>
        </form>
      </FormProvider>

      <footer className={style.footer()}>
        <a
          href={ENV.VITE_TERMS_OF_USE_URL}
          target="_bank"
          className={style.privacyPolicy()}
        >
          {t('common:termsOfUseAndPrivacyPolicy')}
        </a>

        <p className={style.copyright()}>
          &copy; Copyright By IVECO - {t('common:allRightsReserved')}
        </p>
      </footer>
    </main>
  );
}
