import { IAxiosError } from '@/@types/AxiosError';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useToast } from '@/common/hooks/useToast';

import { postApproveRequestApproval } from '../services/postApproveRequestApproval';

export function useApproveRequestApproval() {
  const { t } = useTranslation('invoicing/requestApprovalReview');
  const { toast } = useToast();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: postApproveRequestApproval,
    onError: (errorRequest: IAxiosError) => {
      toast.error(
        t('errorOnRejectRequestApproval'),
        errorRequest?.response?.data?.friendlyMessage,
        {
          toastId: 'approve-request-approval',
        },
      );
    },
  });

  return {
    approveRequestApproval: mutateAsync,
    isApprovingRequestApproval: isLoading,
  };
}
