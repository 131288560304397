import { IDebitNote } from '@/@types/DebitNote';
import { useCallback, useMemo } from 'react';

import { useUserStore } from '@/common/hooks/UserStore';

type PossibleTaxes = {
  cofinsAmount?: number;
  cofinsRate?: number;
  pisAmount?: number;
  pisRate?: number;
};

export function useRecoverTaxes() {
  const { userAccount } = useUserStore();
  const country = userAccount?.Account?.country || 'BR';

  const taxesByCountry: Record<string, string[]> = useMemo(
    () => ({
      BR: ['cofinsAmount', 'cofinsRate', 'pisAmount', 'pisRate'],
      AR: ['IVA'],
    }),
    [],
  );

  const recoverTaxes = useCallback(
    (debitNote: IDebitNote) => {
      const taxes = taxesByCountry[country];

      if (!taxes) {
        return {};
      }

      return taxes.reduce((acc, tax) => {
        return {
          ...acc,
          [tax]: debitNote.metadata.taxDetails[tax],
        };
      }, {} as PossibleTaxes);
    },
    [taxesByCountry, country],
  );

  return { taxesByCountry, recoverTaxes };
}
