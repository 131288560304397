import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { RootLayout } from '@/layouts/Root';

import Panel from '../pages/Panel';

export const PanelRoutes: RouteObject[] = [
  {
    element: <RootLayout />,
    children: [
      {
        path: links.panel,
        element: <Panel />,
      },
    ],
  },
];
