import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { ChatLayout } from '@/layouts/Chat';

const Chat = lazy(() => import('@/modules/chat/pages/Chat'));

export const ChatRoutes: RouteObject[] = [
  {
    element: <ChatLayout />,
    children: [
      {
        path: links.chat,
        element: <Chat />,
      },
    ],
  },
];
