import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { formatCurrency } from '@/common/utils/formatCurrency';
import i18n from '@/config/i18n';
import { useSelectedDebitNote } from '@/modules/invoicing/hooks/useSelectedDebitNote';
import { IDebitNotePaginated } from '@/modules/invoicing/services/getPaginateDebitNote';

import * as styles from './styles';

type TableRowProps = {
  data: IDebitNotePaginated['DebitNoteSignChassisInstallments'][0];
};

export function TableRow({ data }: TableRowProps) {
  const { t } = useTranslation('invoicing/openInvoices');
  const { selectedDebitNote } = useSelectedDebitNote.getState();

  const adjustmentDetails =
    data?.SignChassisInstallments?.metadata?.adjustmentDetails[
      data?.SignChassisInstallments?.metadata?.adjustmentDetails.length - 1
    ];

  return (
    <tr className={styles.container()}>
      <td className={styles.tableData()}>
        {data.SignChassisInstallments.SignChassis.chassis}

        <span className={styles.tableSubData()}>
          {data?.SignChassisInstallments.SignChassis.codeContract} (contrato)
        </span>

        <span className={styles.tableSubData()}>
          {selectedDebitNote?.Sign.Deal.Plan.title}
        </span>
      </td>

      <td className={styles.tableData()}>
        {data?.SignChassisInstallments?.numberInstallment}/
        {data?.SignChassisInstallments?.SignChassis?.quantityInstallments}
        <span className={styles.tableSubData()}>
          {format(
            new Date(data?.SignChassisInstallments?.SignChassis?.startDate),
            'dd/MM/yyyy',
          )}{' '}
          (inicio)
        </span>
        <span className={styles.tableSubData()}>
          {format(
            new Date(data?.SignChassisInstallments?.SignChassis?.endDate),
            'dd/MM/yyyy',
          )}{' '}
          (fim)
        </span>
      </td>

      <td className={styles.tableData()}>
        {Intl.NumberFormat(i18n.language, {
          style: 'decimal',
          maximumFractionDigits: 3,
          minimumFractionDigits: 3,
        }).format(data?.SignChassisInstallments?.SignChassis?.finalMileage)}
        (total)
        <span className={styles.tableSubData()}>
          {' '}
          {Intl.NumberFormat(i18n.language, {
            style: 'decimal',
            maximumFractionDigits: 3,
            minimumFractionDigits: 3,
          }).format(data?.SignChassisInstallments?.monthlyMileage)}{' '}
          (mensal)
        </span>
      </td>

      <td className={styles.tableData()}>
        {formatCurrency(data?.SignChassisInstallments?.monthlyInstallmentPrice)}
        <span className={styles.tableSubData()}>
          {' '}
          {formatCurrency(
            data?.SignChassisInstallments?.pricePerKilometer,
          )}{' '}
          (valor km)
        </span>

        {adjustmentDetails.accrualBasis && (
          <span className={styles.tableSubData()}>
            {t('ipcaAt')}{' '}
            {format(new Date(adjustmentDetails.applicationDate), 'MM/yyyy')}
          </span>
        )}
      </td>
    </tr>
  );
}
