/* eslint-disable @typescript-eslint/ban-ts-comment */
import { format as formatDate, isDate } from 'date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';

import { default as zodEN, default as zodES } from './zod/zodEnUS.json';
import zodBR from './zod/zodPtBR.json';

export const locales = { ptBR, enUS, es };

const detectLanguage = (): string => {
  const storedLanguage = localStorage.getItem('i18nextLng');
  if (!storedLanguage) {
    localStorage.setItem('i18nextLng', 'pt-BR');
    return 'pt-BR';
  } else if (storedLanguage === 'pt-BR') {
    return 'pt-BR';
  } else if (storedLanguage === 'es-ES') {
    return 'es-ES';
  } else {
    return 'en-US';
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    lng: detectLanguage(),
    fallbackLng: detectLanguage(),
    supportedLngs: ['en-US', 'pt-BR', 'es-ES'],
    ns: ['common'],
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (isDate(value) && format && lng) {
          // @ts-ignore
          const locale = locales[lng] || ptBR;
          return formatDate(value, format, { locale });
        }

        return value;
      },
    },
    backend: {
      loadPath: '../../../../locales/{{lng}}/{{ns}}.json',
    },
    detection: {
      order: ['localStorage', 'path', 'querystring', 'navigator'],
      lookupFromPathIndex: 0,
    },
  });

i18n.init({
  partialBundledLanguages: true,
  resources: {
    'pt-BR': {
      zod: zodBR,
    },
    'EN-US': {
      zod: zodEN,
    },
    'es-ES': {
      zod: zodES,
    },
  },
});

z.setErrorMap(makeZodI18nMap());

export default i18n;
