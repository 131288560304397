import { DebitNoteStatus } from '@/@types/DebitNote';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useTranslateDebitNoteStatus() {
  const { t } = useTranslation('common');

  const translateDebitNoteStatus = useCallback(
    (status: DebitNoteStatus) => {
      switch (status) {
        case DebitNoteStatus.FIRST_INSTALLMENT:
          return t('firstInstallment');
        case DebitNoteStatus.LAST_INSTALLMENT:
          return t('lastInstallment');
        case DebitNoteStatus.REGULAR:
          return t('regular');
        case DebitNoteStatus.ADJUSTMENT:
          return t('adjustment');
        case DebitNoteStatus.CANCELLED:
          return t('cancelled');
      }
    },
    [t],
  );

  return { translateDebitNoteStatus };
}
