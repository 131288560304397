import { useUserStore } from '../hooks/UserStore';

export function maskTelephone(data: string, country?: string) {
  const { userAccount } = useUserStore.getState();

  if (country && country !== 'BR') return data;
  if (!country && userAccount?.Account?.country !== 'BR') return data;

  if (data) {
    const cleanedData = data.replace(/\D/g, '');

    if (cleanedData.length === 13) {
      return cleanedData.replace(
        /^(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})$/,
        '+$1 ($2) $3 $4-$5',
      );
    }

    if (cleanedData.length === 11) {
      return cleanedData.replace(
        /^(\d{2})(\d{1})(\d{4})(\d{4})$/,
        '($1) $2 $3-$4',
      );
    }
    if (cleanedData.length === 10) {
      return cleanedData.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
    }

    return data;
  }

  return data;
}
