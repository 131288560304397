import { cva } from 'class-variance-authority';

export const tableRow = cva(['flex', 'gap-2', 'bg-white', 'w-full', 'p-2']);

export const columnHead = cva(
  ['block', 'w-2/6', 'px-6', 'py-3', 'rounded-md', 'bg-gray-200'],
  {
    variants: {
      size: {
        sm: '!w-min',
        lg: '!w-3/6',
      },
    },
  },
);
