import { cva } from 'class-variance-authority';

export const container = cva([
  'min-w-screen',
  'sm:min-h-screen',
  'sm:h-full',
  'h-[calc(var(--vh,1vh)*100)]',
  'overflow-y-hidden',
  'bg-neutral-50',
  'grid md:grid-cols-app',
]);

export const content = cva([
  'overflow-y-auto',
  'p-3',
  'md:p-8',
  'pt-20',
  'w-full',
  'md:h-screen',
  'max-h-screen',
]);
