
import * as style from './styles';

export function TableRowSkeleton() {
  return (
    <div className={style.tableRow()}>
      <div
        className={style.columnHead({
          size: 'sm',
        })}
      />
      <div
        className={style.columnHead({
          size: 'lg',
        })}
      />
      <div className={style.columnHead()} />
      <div className={style.columnHead()} />
      <div
        className={style.columnHead({
          size: 'sm',
        })}
      />
    </div>
  );
}
