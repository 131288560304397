import { cva } from 'class-variance-authority';

export const container = cva([
  'flex flex-col gap-4 p-4',
  'w-full',
  'border rounded-lg border-secondary-1 bg-white',
]);

export const header = cva(['flex gap-2 items-center mb-2']);

export const icon = cva(['text-brand-pure', 'h-4 w-4']);

export const title = cva(['text-base text-neutral-2 font-semibold']);

export const dataList = cva(
  ['list-disc', 'px-10 w-full', 'grid gap-x-8 gap-y-2'],
  {
    variants: {
      cols: {
        1: 'grid-cols-1',
        2: 'grid-cols-2',
        3: 'grid-cols-3',
        4: 'grid-cols-4',
        5: 'grid-cols-5',
        6: 'grid-cols-6',
        7: 'grid-cols-7',
        8: 'grid-cols-8',
      },
    },
  },
);

export const info = cva(['text-sm', 'text-neutral-2', 'font-light']);
