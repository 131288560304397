import { format } from 'date-fns';
import { FileText, Store, User2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { DisplayInfoCard } from '@/common/components/DisplayInfoCard';
import { useLabelFor } from '@/common/hooks/useLabelFor';
import { useTranslateFormPayment } from '@/common/hooks/useTranslateFormPayment';
import { useTranslateGenre } from '@/common/hooks/useTranslateGenre';
import { maskCompanyDocument, maskTelephone } from '@/common/utils';
import { capitalizeString } from '@/common/utils/capitalizeString';
import { useSelectedDebitNote } from '@/modules/invoicing/hooks/useSelectedDebitNote';

import * as style from './styles';

export function GeneralData() {
  const { t } = useTranslation('invoicing/openInvoices');
  const { selectedDebitNote } = useSelectedDebitNote();

  const translateGenre = useTranslateGenre();
  const translateFormPayment = useTranslateFormPayment();
  const { companyLabel } = useLabelFor();

  if (!selectedDebitNote) {
    return null;
  }

  const sections = [
    {
      icon: Store,
      title: selectedDebitNote?.Customer.name || '',
      cols: 1 as const,
      info: [
        `${t('clientSAPCode')} ${selectedDebitNote?.Customer.externalCode}`,
        `${companyLabel}: ${maskCompanyDocument(
          selectedDebitNote?.Customer.documentNumber || '',
        )}`,
      ],
    },
    {
      icon: User2,
      title: selectedDebitNote?.Sign.SignPerson[0].name || '',
      cols: 2 as const,
      info: [
        selectedDebitNote?.Sign.SignPerson[0].email || '',
        `${t('common:genre')} ${
          translateGenre[
            selectedDebitNote?.Sign?.SignPerson[0].genre || 'OTHER'
          ]
        }`,
        selectedDebitNote?.Sign.SignPerson[0].birthDate
          ? `${t('birthDate')} ${format(
              new Date(selectedDebitNote?.Sign.SignPerson[0].birthDate),
              'dd/MM/yyyy',
            )}`
          : '',
        maskTelephone(selectedDebitNote?.Sign?.SignPerson[0].phone || ''),
      ],
    },
    {
      icon: FileText,
      title: selectedDebitNote?.numberDebitNote || '',
      cols: 3 as const,
      info: [
        `${t('startDate')} ${format(
          new Date(
            selectedDebitNote?.DebitNoteSignChassisInstallments[0]?.SignChassisInstallments.SignChassis?.startDate,
          ),
          'dd/MM/yyyy',
        )}`,
        `${t('installment')} ${
          selectedDebitNote?.DebitNoteSignChassisInstallments[0]
            ?.SignChassisInstallments.numberInstallment
        }/${
          selectedDebitNote?.DebitNoteSignChassisInstallments[0]
            ?.SignChassisInstallments.SignChassis.quantityInstallments
        }`,
        selectedDebitNote?.Sign.formPayment
          ? `${t('modality')} ${capitalizeString(
              translateFormPayment[selectedDebitNote.Sign.formPayment],
            )}`
          : '',

        `${t('endDate')} ${format(
          new Date(
            selectedDebitNote?.DebitNoteSignChassisInstallments[0]
              ?.SignChassisInstallments.SignChassis?.endDate || '',
          ),
          'dd/MM/yyyy',
        )}`,
        `${t('plan')} ${selectedDebitNote?.Sign.Deal.Plan.title}`,
      ],
    },
  ];

  return (
    <div className={style.container()}>
      <h3 className={style.title()}>{t('clientData')}</h3>
      <DisplayInfoCard sections={[sections[0]]} />

      <h3 className={style.title()}>{t('responsibleData')}</h3>
      <DisplayInfoCard sections={[sections[1]]} />

      <h3 className={style.title()}>{t('contractData')}</h3>
      <DisplayInfoCard sections={[sections[2]]} />
    </div>
  );
}
