import { cva } from 'class-variance-authority';

export const container = cva(
  [
    'w-full min-w-max  h-full',
    'flex items-center gap-2 p-4',
    'rounded-2xl bg-white shadow-md',
    'relative',
    'data-[swapy-dragging]:border data-[swapy-dragging]:border-neutral-200 data-[swapy-dragging]:opacity-80',
  ],
  {
    variants: {
      direction: {
        row: 'flex-row',
        column: 'flex-col justify-baseline items-baseline',
      },
    },
    defaultVariants: {
      direction: 'row',
    },
  },
);

export const handle = cva(['absolute right-2 top-2 cursor-grab']);

export const handleIcon = cva(['h-4 w-4 text-neutral-600']);

export const icon = cva(['h-12 w-12']);

export const content = cva(['flex flex-col gap-2']);

export const description = cva(['text-sm font-normal text-neutral-600']);

export const value = cva(['text-2xl font-normal text-slate-950']);

export const extraInfo = cva(['text-xs font-normal text-slate-950']);
