import Cookies from 'js-cookie';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { httpClient } from '@/config/httpClient';
import { promptHttpClient } from '@/config/promptHttpClient';
import { ProtectedRoutes } from '@/routes/protected.routes';
import { PublicRoutes } from '@/routes/public.routes';

import { logoutUserExpiredSession } from '../utils/logoutUserExpiredSession';

import { useToast } from './useToast';

type AppRoutesData = {
  activeRouter: typeof ProtectedRoutes;
  changeRouter(router: typeof ProtectedRoutes): void;
};

export const AppRoutesContext = createContext<AppRoutesData>(
  {} as AppRoutesData,
);

const AppRouterProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('login/signIn');
  const { toast } = useToast();

  const [activeRouter, setActiveRouter] = useState(() => {
    const accessToken = Cookies.get('accessToken');
    const userWasLoggedIn = Cookies.get('userType');

    if (accessToken) {
      httpClient.defaults.headers.Authorization = `Bearer ${accessToken}`;
      promptHttpClient.defaults.headers.Authorization = `Bearer ${accessToken}`;
      return ProtectedRoutes;
    }

    if (userWasLoggedIn) {
      toast.info(t('sessionExpired'), t('signInToContinue'), {
        toastId: 'session-expired',
        autoClose: 6000,
      });

      logoutUserExpiredSession();
    }

    return PublicRoutes;
  });

  const changeRouter = (router: typeof ProtectedRoutes) => {
    setActiveRouter(router);
  };

  return (
    <AppRoutesContext.Provider value={{ activeRouter, changeRouter }}>
      {children}
    </AppRoutesContext.Provider>
  );
};

function useAppRouter() {
  const context = useContext(AppRoutesContext);

  if (!context) {
    throw new Error('useAppRouter must be used within AppRouterProvider');
  }

  return context;
}

export { AppRouterProvider, useAppRouter };
