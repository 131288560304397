import { zodResolver } from '@hookform/resolvers/zod';
import Cookies from 'js-cookie';
import { Stamp } from 'lucide-react';
import { useCallback, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { Alert } from '@/common/components/Alert';
import { AlertModal } from '@/common/components/AlertModal';
import Button from '@/common/components/Button';
import { CardNoResults } from '@/common/components/CardNoResults';
import { TextArea } from '@/common/components/TextArea';
import { useToast } from '@/common/hooks/useToast';
import { generateId } from '@/common/utils';
import { links } from '@/common/utils/links';
import { parseJWT } from '@/common/utils/parseJWT';

import { DrawerViewDebitNote } from '../../components/DrawerViewDebitNote';
import { useApproveRequestApproval } from '../../hooks/useApproveRequestApproval';
import { useFinishRequestApproval } from '../../hooks/useFinishRequestApproval';
import { useFlagDebitNotes } from '../../hooks/useFlagDebitNotes';
import { useInfiniteBatchDebitNote } from '../../hooks/useInfiniteBatchDebitNote';
import { useRejectRequestApproval } from '../../hooks/useRejectRequestApproval';
import { useRequestApprovalRemoveDebitNotes } from '../../hooks/useRequestApprovalRemoveDebitNotes';
import {
  FlagDebitNoteForm,
  FlagDebitNoteSchema,
} from '../../schemas/FlagDebitNoteSchema';
import {
  RequestApprovalReviewForm,
  RequestApprovalReviewSchema,
} from '../../schemas/RequestApprovalReviewSchema';
import { IBatchDebitNotePaginated } from '../../services/getPaginateBatchDebitNote';
import { FlagDebitNotesProps } from '../../services/postFlagDebitNotes';

import { TableHead } from './components/TableHead';
import { TableRow } from './components/TableRow';
import { TableRowSkeleton } from './components/TableRowSkeleton';
import { TableSearch } from './components/TableSearch';
import * as style from './styles';

interface IReviewToken {
  email: string;
  userId: string;
  accountId: string;
  flow: 'COMPLIANCE' | 'APPROVAL';
}

export default function RequestApprovalReview() {
  const [tableSearch, setTableSearch] = useState('');
  const [selectedBatchDebitNotes, setSelectedBatchDebitNotes] = useState<
    IBatchDebitNotePaginated[]
  >([]);

  const { t } = useTranslation(['invoicing/requestApprovalReview', 'common']);
  const { batchId } = useParams();
  const { toast } = useToast();
  const observer = useRef<IntersectionObserver>();
  const navigate = useNavigate();

  const hookFormFinish = useForm<RequestApprovalReviewForm>({
    resolver: zodResolver(RequestApprovalReviewSchema),
  });
  const hookFormFlagDebitNotes = useForm<FlagDebitNoteForm>({
    resolver: zodResolver(FlagDebitNoteSchema),
  });

  const { removeDebitNotes, isRemovingDebitNotes } =
    useRequestApprovalRemoveDebitNotes();
  const {
    batchDebitNotes,
    isLoadingBatchDebitNotes,
    hasMoreDebitNotes,
    isFetchingDebitNotes,
    fetchMoreDebitNotes,
  } = useInfiniteBatchDebitNote(batchId, tableSearch);
  const { flagDebitNotes, isFlaggingDebitNotes } = useFlagDebitNotes();
  const { finishRequestApproval, isFinishingRequestApproval } =
    useFinishRequestApproval();
  const { rejectRequestApproval, isRejectingRequestApproval } =
    useRejectRequestApproval();
  const { approveRequestApproval, isApprovingRequestApproval } =
    useApproveRequestApproval();

  const jwtToken = Cookies.get('jwt-access-batch');
  const jwtPayload = parseJWT<IReviewToken>(jwtToken);

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoadingBatchDebitNotes) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (
          entries[0].isIntersecting &&
          hasMoreDebitNotes &&
          !isFetchingDebitNotes
        ) {
          fetchMoreDebitNotes();
        }
      });

      if (node) observer.current.observe(node);
    },
    [
      fetchMoreDebitNotes,
      hasMoreDebitNotes,
      isFetchingDebitNotes,
      isLoadingBatchDebitNotes,
    ],
  );

  const handleSelectAllRows = () => {
    if (selectedBatchDebitNotes.length === batchDebitNotes?.length) {
      setSelectedBatchDebitNotes([]);
      return;
    }

    const checkBoxSelectAll = document.getElementById(
      'hs-table-pagination-checkbox-all',
    ) as HTMLInputElement;

    if (
      selectedBatchDebitNotes.length &&
      selectedBatchDebitNotes.length !== batchDebitNotes?.length &&
      checkBoxSelectAll.hasAttribute('checked')
    ) {
      setSelectedBatchDebitNotes([]);
      return;
    }

    setSelectedBatchDebitNotes(batchDebitNotes ?? []);
  };

  const handleSelectRow = (batchSelected: IBatchDebitNotePaginated) => {
    setSelectedBatchDebitNotes(state =>
      state.find(item => item.id === batchSelected.id)
        ? state.filter(item => item.id !== batchSelected.id)
        : [...state, batchSelected],
    );
  };

  const handleFinishReview = async () => {
    await hookFormFinish.handleSubmit(
      async (data: RequestApprovalReviewForm) => {
        await finishRequestApproval({
          batchId: batchId!,
          comment: data.comment,
        });
      },
    )();

    const debitNotesFlagged = hookFormFlagDebitNotes.getValues();

    const flagDebitNotesPayload = {
      batchDebitNotesToFlag: Object.entries(debitNotesFlagged)
        .map(itemToFlagAsArray => ({
          id: itemToFlagAsArray[0],
          comment: itemToFlagAsArray[1],
        }))
        .filter(itemToFlagAsObject => itemToFlagAsObject.comment),
    } as FlagDebitNotesProps;

    if (flagDebitNotesPayload.batchDebitNotesToFlag.length) {
      await flagDebitNotes(flagDebitNotesPayload);
    }

    const debitNotesToRemove = selectedBatchDebitNotes.map(item => item.id);

    if (debitNotesToRemove.length) {
      await removeDebitNotes(debitNotesToRemove);
    }

    window.HSOverlay.close(
      document.getElementById('hs-modal-confirm-finish-review')!,
    );

    navigate(links.invoicing.requestApprovalSuccess, {
      state: {
        type: 'finished',
      },
    });
  };

  const handleRejectRequest = async () => {
    await hookFormFinish.handleSubmit(
      async (data: RequestApprovalReviewForm) => {
        await rejectRequestApproval({
          batchId: batchId!,
          comment: data.comment,
        });
      },
    )();

    window.HSOverlay.close(document.getElementById('hs-modal-reject-request')!);

    navigate(links.invoicing.requestApprovalSuccess, {
      state: {
        type: 'rejected',
      },
    });
  };

  const handleApproveRequest = async () => {
    await hookFormFinish.handleSubmit(
      async (data: RequestApprovalReviewForm) => {
        await approveRequestApproval({
          batchId: batchId!,
          comment: data.comment,
        });
      },
    )();

    window.HSOverlay.close(
      document.getElementById('hs-modal-approve-request')!,
    );

    navigate(links.invoicing.requestApprovalSuccess, {
      state: {
        type: 'approved',
      },
    });
  };

  if (!jwtToken || !jwtPayload) {
    toast.warn(t('identityNotFoundTitle'), t('identityNotFoundDescription'), {
      autoClose: 6000,
      toastId: 'identity-not-found',
    });

    return (
      <Navigate to={links.invoicing.requestApprovalValidateAccess(batchId!)} />
    );
  }

  return (
    <div className={style.container()}>
      <div className={style.info()}>
        <h1 className={style.title()}>
          {jwtPayload.flow === 'COMPLIANCE'
            ? t('titleCompliance')
            : t('titleApprover')}
        </h1>

        {jwtPayload.flow === 'COMPLIANCE' && (
          <Alert
            type="info"
            description={
              <p>
                {t('alertInstructionsRemoveDebitNote')}
                <br />
                {t('alertInstructionsFinishReview')}
              </p>
            }
          />
        )}
      </div>

      <div className={style.tableContainer()}>
        <TableSearch
          onSearch={setTableSearch}
          selectedBatchDebitNotes={selectedBatchDebitNotes}
        />
        <table className={style.table()}>
          <TableHead
            data={[]}
            disableCheckbox={jwtPayload.flow !== 'COMPLIANCE'}
            selectedBatchs={selectedBatchDebitNotes}
            onSelectAllRows={handleSelectAllRows}
          />

          <tbody className={style.tableBody()}>
            {isLoadingBatchDebitNotes &&
              Array.from({ length: 10 }).map(() => (
                <TableRowSkeleton key={generateId()} />
              ))}

            <FormProvider {...hookFormFlagDebitNotes}>
              {batchDebitNotes?.map(row => (
                <div key={row?.id} ref={lastElementRef}>
                  <TableRow
                    disableCheckbox={jwtPayload.flow !== 'COMPLIANCE'}
                    data={row}
                    selectedDebitNotes={selectedBatchDebitNotes}
                    onSelectRow={handleSelectRow}
                  />
                </div>
              ))}
            </FormProvider>
          </tbody>
        </table>

        {!isLoadingBatchDebitNotes && batchDebitNotes?.length === 0 && (
          <CardNoResults />
        )}
      </div>

      <div className={style.actions()}>
        <FormProvider {...hookFormFinish}>
          <form className={style.form()}>
            <TextArea
              name="comment"
              placeholder={t('commentPlaceholder')}
              rows={3}
            />

            <div className="flex gap-2">
              {jwtPayload.flow === 'APPROVAL' && (
                <Button
                  type="button"
                  variant="danger"
                  text={t('reject')}
                  className="w-fit"
                  disabled={!hookFormFinish.watch('comment')}
                  data-hs-overlay="#hs-modal-reject-request"
                />
              )}

              {jwtPayload.flow === 'APPROVAL' && (
                <Button
                  type="button"
                  variant="primary"
                  text={t('approve')}
                  className="w-fit"
                  disabled={!hookFormFinish.watch('comment')}
                  data-hs-overlay="#hs-modal-approve-request"
                />
              )}

              {jwtPayload.flow === 'COMPLIANCE' && (
                <Button
                  type="button"
                  variant="primary"
                  text={t('finish')}
                  className="w-fit"
                  data-hs-overlay="#hs-modal-confirm-finish-review"
                />
              )}
            </div>
          </form>
        </FormProvider>
      </div>

      <AlertModal
        id="confirm-finish-review"
        type="primary"
        title={t('finishReview')}
        subTitle={t('finishReviewMessage')}
        confirmText={t('common:confirm')}
        confirmIcon={Stamp}
        cancelText={t('common:cancel')}
        isLoading={
          isFlaggingDebitNotes ||
          isRemovingDebitNotes ||
          isFinishingRequestApproval
        }
        onConfirm={handleFinishReview}
      />

      <AlertModal
        id="reject-request"
        type="danger"
        title={t('rejectRequest')}
        subTitle={t('finishReviewMessage')}
        confirmText={t('common:confirm')}
        confirmIcon={Stamp}
        cancelText={t('common:cancel')}
        isLoading={isRejectingRequestApproval}
        onConfirm={handleRejectRequest}
      />

      <AlertModal
        id="approve-request"
        type="primary"
        title={t('approveRequest')}
        subTitle={t('finishReviewMessage')}
        confirmText={t('common:confirm')}
        confirmIcon={Stamp}
        cancelText={t('common:cancel')}
        isLoading={isApprovingRequestApproval}
        onConfirm={handleApproveRequest}
      />

      <DrawerViewDebitNote />
    </div>
  );
}
