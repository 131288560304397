import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export function ResetPasswordSchema() {
  const { t } = useTranslation('login/resetPassword');

  return z
    .object({
      password: z.string().nonempty(),
      confirmPassword: z.string().optional(),
    })
    .refine(data => data.password === data.confirmPassword, {
      message: t('passwordsDoNotMatch'),
      path: ['confirmPassword'],
    });
}

type Schema = ReturnType<typeof ResetPasswordSchema>;

export type ResetPasswordForm = z.infer<Schema>;
