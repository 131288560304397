import { IStaticMethods } from 'preline/preline';
import { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { LoadingFallback } from '@/common/components/LoadingFallback';
import SidebarSkeleton from '@/common/components/SidebarSkeleton';

import { AccountSelectionModal } from './components/AccountSelectionModal';
import { Sidebar } from './components/Sidebar';
import { StoreSelectionModal } from './components/StoreSelectionModal';
import { container, content } from './styles';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

export function RootLayout() {
  const location = useLocation();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  useEffect(() => {
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);

    return () => {
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);

  return (
    <div className={container()}>
      <Suspense fallback={<SidebarSkeleton />}>
        <Sidebar />
      </Suspense>

      <div className={content()}>
        <Suspense fallback={<LoadingFallback />}>
          <Outlet />
        </Suspense>
      </div>

      <StoreSelectionModal />
      <AccountSelectionModal />
    </div>
  );
}
