import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { RootLayout } from '@/layouts/Root';

const BatchInvoices = lazy(
  () => import('@/modules/invoicing/pages/BatchInvoices'),
);
const Invoice = lazy(() => import('@/modules/invoicing/pages/Invoicing'));
const OpenInvoices = lazy(
  () => import('@/modules/invoicing/pages/OpenInvoices'),
);

export const InvoicingRoutes: RouteObject[] = [
  {
    element: <RootLayout />,
    children: [
      {
        path: links.invoicing.invoicing,
        element: <Invoice />,
      },
      {
        path: links.invoicing.openInvoices,
        element: <OpenInvoices />,
      },
      {
        path: links.invoicing.batchInvoices(),
        element: <BatchInvoices />,
      },
    ],
  },
];
