import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import * as style from './styles';

type SpinnerProps = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  variant?: 'brand' | 'neutral' | 'danger' | 'white';
  className?: string;
};

export function Spinner({
  size = 'sm',
  variant = 'brand',
  className,
}: SpinnerProps) {
  const { t } = useTranslation('common');

  return (
    <div
      className={clsx(style.Loading({ size, variant }), className)}
      role="status"
      aria-label="loading"
    >
      <span className="sr-only">{t('loading')}</span>
    </div>
  );
}
