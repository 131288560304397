import Cookies from 'js-cookie';

import { httpClient } from '@/config/httpClient';

export type FlagDebitNotesProps = {
  batchDebitNotesToFlag: {
    id: string;
    comment: string;
  }[];
};

export async function postFlagDebitNotes(data: FlagDebitNotesProps) {
  return httpClient.post('/batch-debit-note/flag-debit-note', data, {
    headers: {
      Authorization: `Bearer ${Cookies.get('jwt-access-batch')}`,
    },
  });
}
