import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { useUserStore } from '@/common/hooks/UserStore';
import { links } from '@/common/utils/links';
import { RootLayout } from '@/layouts/Root';

const Users = lazy(() => import('../pages/users'));
const Dealer = lazy(() => import('../pages/dealer'));
const Products = lazy(() => import('../pages/products'));
const Materials = lazy(() => import('../pages/materials'));
const PlanProductsParts = lazy(() => import('../pages/planProductsParts'));
const Plans = lazy(() => import('../pages/plans'));
const ContractTemplate = lazy(() => import('../pages/contractTemplate'));
const Models = lazy(() => import('../pages/models'));
const GlobalConfig = lazy(() => import('../pages/globalConfig'));
const Chassis = lazy(() => import('../pages/chassis'));
const SettingsOptions = lazy(() => import('../pages/settingsOptions'));
const PriceTable = lazy(() => import('../pages/priceTable'));
const Campaigns = lazy(() => import('../pages/campaigns'));
const ResendActivation = lazy(() => import('../pages/resendActivation'));
const Ipca = lazy(() => import('../pages/ipca'));
const MonetaryVariation = lazy(() => import('../pages/monetaryVariation'));
const Accounts = lazy(() => import('../pages/accounts'));
const Account = lazy(() => import('../pages/account'));

function AccountElement() {
  const { userAccount } = useUserStore();

  if (userAccount?.Account?.isMain) {
    return <Accounts />;
  }
  return <Account />;
}

export const SettingsRoutes: RouteObject[] = [
  {
    element: <RootLayout />,
    children: [
      {
        children: [
          {
            path: links.settings.users,
            element: <Users />,
          },
          {
            path: links.settings.dealer,
            element: <Dealer />,
          },
          {
            path: links.settings.products,
            element: <Products />,
          },
          {
            path: links.settings.models,
            element: <Models />,
          },
          {
            path: links.settings.materials,
            element: <Materials />,
          },
          {
            path: links.settings.plans,
            element: <Plans />,
          },
          {
            path: links.settings.planProductsParts,
            element: <PlanProductsParts />,
          },
          {
            path: links.settings.contractTemplate,
            element: <ContractTemplate />,
          },
          {
            path: links.settings.globalConfig,
            element: <GlobalConfig />,
          },
          {
            path: links.settings.chassis,
            element: <Chassis />,
          },
          {
            path: links.settings.settingsOptions,
            element: <SettingsOptions />,
          },
          {
            path: links.settings.priceTable,
            element: <PriceTable />,
          },
          {
            path: links.settings.campaigns,
            element: <Campaigns />,
          },
          {
            path: links.settings.resendActivation,
            element: <ResendActivation />,
          },
          {
            path: links.settings.ipca,
            element: <Ipca />,
          },
          {
            path: links.settings.monetaryVariation,
            element: <MonetaryVariation />,
          },
          {
            path: links.settings.account,
            element: <AccountElement />,
          },
        ],
      },
    ],
  },
];
