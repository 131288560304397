import { IVehicleRanking } from '@/@types/Vehicle';
import { useFlags } from 'flagsmith/react';
import { Car, GripVertical } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { formatCurrency } from '@/common/utils/formatCurrency';
import i18n from '@/config/i18n';

import * as style from './styles';

type RankingVehiclesProps = {
  data: IVehicleRanking[];
};

export function RankingVehicles({ data }: RankingVehiclesProps) {
  const { t } = useTranslation('panel/panel');
  const { enabled_swapy_dashboard } = useFlags(['enabled_swapy_dashboard']);

  return (
    <div className={style.container()} data-swapy-item="rankingVehicles">
      {enabled_swapy_dashboard.enabled && (
        <div data-swapy-handle className={style.handle()}>
          <GripVertical className={style.handleIcon()} />
        </div>
      )}

      <div className={style.titleContainer()}>
        <Car className={style.icon()} />

        <p className={style.title()}>{t('rankingVehicles')}</p>
      </div>

      <ul className={style.list()}>
        {data.map((vehicle, index) => (
          <li className={style.listItem()} key={vehicle.productModelId}>
            <div className={style.subject()}>
              <div className={style.info()}>
                <span className={style.position()}>{index + 1}º</span>
                <span className={style.name()}>{vehicle.title}</span>
              </div>

              <div className="flex w-full justify-between gap-2">
                <span className={style.subData()}>
                  {t('totalChassis')} <b>{vehicle.quantityOfChassis}</b>
                </span>

                <span className={style.total()}>
                  {t('total')} <b>{formatCurrency(vehicle.totalAmount)}</b>
                </span>
              </div>

              <span className={style.rateConversion()}>
                (
                <b>
                  {Intl.NumberFormat(i18n.language, {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(vehicle.conversionRate * 100)}
                  %
                </b>{' '}
                {t('totalConversion')})
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
