import type { VariantProps } from 'class-variance-authority';
import { ElementType } from 'react';

import * as style from './styles';

export type DisplayInfoCardProps = {
  sections: {
    icon: ElementType;
    title: string;
    cols: VariantProps<typeof style.dataList>['cols'];
    info: string[];
  }[];
};

export function DisplayInfoCard({ sections }: DisplayInfoCardProps) {
  return (
    <div className={style.container()}>
      {sections.map(section => {
        const Icon = section.icon;

        return (
          <div key={section.title}>
            <div className={style.header()}>
              <Icon className={style.icon()} strokeWidth={1.5} />
              <p className={style.title()}>{section.title}</p>
            </div>

            <ul
              className={style.dataList({
                cols: section.cols,
              })}
            >
              {section.info.map(item => (
                <li key={item} className={style.info()}>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
