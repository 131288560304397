import {
  DealerStoreUser,
  IDealerExecutiveUser,
  IDealerStoreUser,
} from '@/@types/DealerStoreUsers';
import { IPerson } from '@/@types/Person';
import { IStore, UserStores } from '@/@types/Store';
import { IUser, IUserAccount, UserRoleEnum, UserTypeEnum } from '@/@types/User';
import { IUserPreference } from '@/@types/UserPreference';
import { create } from 'zustand';

import { GetUserInfo } from '../services/getUserInfo';

export type SaveUserStoreProps = {
  dealerId: string;
  storeId: string;
  role: UserRoleEnum;
};

export type SaveUserAccountProps = {
  accountId: string;
  role: UserRoleEnum;
};

type UserStoreState = {
  user: IUser;
  userType: UserTypeEnum;
  personId: string;
  dealerId: string;
  storeId: string | null;
  role: UserRoleEnum;
  userPreference: IUserPreference;
  person: IPerson;
  accountId?: string | null;
  dealerStoreUsers?: Array<IDealerExecutiveUser | IDealerStoreUser>;
  userAccounts?: IUserAccount[];
  userAccount?: IUserAccount;
  userStores?: UserStores[];
  store?: IStore & { Dealer: DealerStoreUser } & { isDealer?: boolean };
};

type UserStoreActions = {
  saveAccountUser: (data: GetUserInfo) => IUserAccount[];
  saveDealerUser: (data: GetUserInfo) => UserStores[];
  saveUserStore: (data: SaveUserStoreProps) => void;
  saveUserAccount: (data: SaveUserAccountProps) => void;
  removeUser: () => void;
};

export const useUserStore = create<UserStoreState & UserStoreActions>(set => {
  const saveAccountUser = (data: GetUserInfo) => {
    if (data.userType !== UserTypeEnum.ACCOUNT) return [];

    const { userType, personId, Person, UserAccount, UserPreference, ...user } =
      data;

    set({
      userType,
      personId,
      person: Person,
      userPreference: UserPreference,
      userAccounts: UserAccount,
      user: {
        ...user,
        personId,
        userType,
      },
    });

    return UserAccount;
  };

  const saveUserAccount = (data: SaveUserAccountProps) => {
    const { accountId, role } = data;

    set(state => ({
      accountId,
      role,
      userAccount: state?.userAccounts?.find(
        account => account?.accountId === accountId,
      ),
    }));
  };

  const saveDealerUser = (data: GetUserInfo): UserStores[] => {
    if (data.userType !== UserTypeEnum.DEALER) return [];

    const {
      userType,
      personId,
      Person,
      DealerStoreUsers,
      UserPreference,
      ...user
    } = data;

    const userStores = DealerStoreUsers.reduce((acc, dealerStore) => {
      if (dealerStore.storeId) {
        acc.push({
          Dealer: dealerStore.Dealer,
          ...dealerStore.Store,
          role: dealerStore.role,
          dealerId: dealerStore.dealerId,
          isDealer: false,
        });
      }

      if (dealerStore.storeId === null) {
        dealerStore.Stores.forEach(store => {
          acc.push({
            Dealer: dealerStore.Dealer,
            ...store,
            role: dealerStore.role,
            dealerId: dealerStore.dealerId,
            isDealer: true,
          });
        });
      }

      return acc;
    }, [] as UserStores[]);

    const userAccounts = DealerStoreUsers.reduce((acc, dealerStore) => {
      acc.push({
        Account: dealerStore.Dealer.Account,
        accountId: dealerStore.Dealer.Account.id,
        userId: user.id,
        role: dealerStore.role,
        status: user.status,
      });
      return acc;
    }, [] as IUserAccount[]);

    set({
      userType,
      personId,
      person: Person,
      dealerStoreUsers: DealerStoreUsers,
      userPreference: UserPreference,
      userStores,
      userAccounts,
      user: {
        ...user,
        personId,
        userType,
      },
    });

    return userStores;
  };

  const saveUserStore = (data: SaveUserStoreProps) => {
    const { dealerId, storeId, role } = data;

    set(state => ({
      dealerId,
      storeId,
      role,
      store: state?.userStores?.find(store => store?.id === storeId),
    }));
  };

  const removeUser = () => {
    set({
      user: {} as IUser,
      userType: '' as UserTypeEnum,
      personId: '',
      dealerId: '',
      accountId: null,
      storeId: null,
      role: '' as UserRoleEnum,
      userPreference: {} as IUserPreference,
      person: {} as IPerson,
      dealerStoreUsers: [],
      userAccount: {} as IUserAccount,
      userStores: [],
      store: {} as IStore & { Dealer: DealerStoreUser },
    });
  };

  return {
    user: {} as IUser,
    userType: '' as UserTypeEnum,
    personId: '',
    dealerId: '',
    accountId: null,
    storeId: null,
    role: '' as UserRoleEnum,
    userPreference: {} as IUserPreference,
    person: {} as IPerson,
    dealerStoreUsers: [],
    userAccount: {} as IUserAccount,
    userStores: [],
    store: {} as IStore & { Dealer: DealerStoreUser },
    saveAccountUser,
    saveDealerUser,
    saveUserStore,
    removeUser,
    saveUserAccount,
  };
});
