import { cva } from 'class-variance-authority';

export const container = cva([
  'min-w-screen',
  'min-h-screen',
  'h-full',
  'overflow-y-hidden',
  'dark:bg-chat-bg',
  'bg-white',
  'text-neutral-900',
  'dark:text-neutral-200',
]);

export const content = cva(['overflow-y-hidden', 'w-full', 'h-screen']);
