import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { classMerge } from '@/common/utils';

import * as style from './styles';

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipContainer = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={classMerge(style.Content(), className)}
    {...props}
  />
));

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

type TooltipProps = {
  children: React.ReactNode;
  message?: React.ReactNode;
  notAllowed?: boolean;
  inactive?: boolean;
};

export function Tooltip({
  children,
  message,
  notAllowed = false,
  inactive = false,
}: TooltipProps) {
  const { t } = useTranslation('common');

  if (inactive) return children;

  return (
    <TooltipProvider>
      <TooltipContainer>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        {notAllowed && (
          <TooltipContent>
            <p>{t('profileNotAllowedPerformAction')}</p>
          </TooltipContent>
        )}

        {message && (
          <TooltipContent>
            {typeof message === 'string' ? <p>{message}</p> : message}
          </TooltipContent>
        )}
      </TooltipContainer>
    </TooltipProvider>
  );
}
