import { cva } from 'class-variance-authority';

export const Container = cva(
  [
    'hs-overlay',
    'hs-overlay-open:translate-x-0',
    'translate-x-full',
    'transition-all',
    'duration-500',
    'transform',
    'hidden',
    'fixed',
    'top-0',
    'right-0',
    'bottom-0',
    'z-[49]',
    'w-full',
    'md:w-4/6',
    'bg-neutral-50',
    'border-r',
    'border-gray-200',
    'rounded-tl-2xl',
    'rounded-bl-2xl',
  ],
  {
    variants: {
      dispensable: {
        false: ['[--overlay-backdrop:static]'],
      },
    },
  },
);

export const Content = cva(['w-full', 'h-full', 'flex', 'flex-col', 'py-6']);

export const Header = cva(['flex', 'flex-col', 'gap-1', 'px-8']);

export const Main = cva([
  'grow',
  'pt-3',
  'mb-1',
  'overflow-y-auto',
  'max-h-[calc(100vh-11.5rem)]',
  'px-8',
]);

export const Footer = cva(['w-full', 'flex', 'flex-col', 'gap-6', 'px-8']);

export const ActionsContainer = cva([
  'w-full',
  'flex',
  'justify-between',
  'items-center',
]);

export const Actions = cva(['flex', 'gap-4']);

export const WrapperTitle = cva([
  'w-full',
  'flex',
  'justify-between',
  'items-center',
  'mb-2',
]);

export const Title = cva(['flex', 'gap-4', 'items-center']);

export const TitleText = cva([
  'text-brand-800',
  'font-semibold',
  'text-3xl',
  'select-none',
]);

export const CloseContainer = cva(['flex', 'items-center', 'gap-8']);

export const Close = cva(['cursor-pointer', 'h-fit', 'hs-dropdown-toggle']);

export const CloseIcon = cva(['hs-dropdown-toggle'], {
  variants: {
    brand: {
      true: ['text-brand-900'],
      false: [],
    },
    white: {
      true: ['text-white'],
      false: [],
    },
  },
  defaultVariants: {
    brand: false,
    white: false,
  },
});

export const Divider = cva([
  'w-full',
  'border-b-2',
  'mt-5',
  'border-b-neutral-200',
]);
