import { z } from 'zod';

export function TableSearchSchema() {
  return z.object({
    search: z
      .string()
      .min(2)
      .nonempty()
      .transform(value => value.trim()),
  });
}

type Schema = ReturnType<typeof TableSearchSchema>;

export type TableSearchForm = z.infer<Schema>;
