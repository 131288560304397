import { format } from 'date-fns';
import { FileSearch } from 'lucide-react';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SituationBadge } from '@/common/components/SituationBadge';
import { TextInput } from '@/common/components/TextInput';
import { Tooltip } from '@/common/components/Tooltip';
import { formatCurrency } from '@/common/utils/formatCurrency';
import { useRecoverTaxes } from '@/modules/invoicing/hooks/useRecoverTaxes';
import { useSelectedDebitNote } from '@/modules/invoicing/hooks/useSelectedDebitNote';
import { IBatchDebitNotePaginated } from '@/modules/invoicing/services/getPaginateBatchDebitNote';
import { IDebitNotePaginated } from '@/modules/invoicing/services/getPaginateDebitNote';

import * as styles from './styles';

type TableRowProps = {
  data: IBatchDebitNotePaginated;
  selectedDebitNotes: IBatchDebitNotePaginated[];
  onSelectRow: (debitNote: IBatchDebitNotePaginated) => void;
  disableCheckbox: boolean;
};

export function TableRow({
  data,
  selectedDebitNotes,
  onSelectRow,
  disableCheckbox,
}: TableRowProps) {
  const { t } = useTranslation([
    'invoicing/batchInvoices',
    'common',
    'invoicing/requestApprovalReview',
  ]);
  const { selectDebitNote } = useSelectedDebitNote();
  const { recoverTaxes } = useRecoverTaxes();

  const taxes = recoverTaxes(data.DebitNote);

  const isChecked = !!selectedDebitNotes.find(
    debitNote => debitNote.id === data.id,
  );

  useLayoutEffect(() => {
    window.HSStaticMethods.autoInit();
  }, []);

  return (
    <tr className={styles.container() + ' flex-col'}>
      <td className={styles.container() + ' items-center'}>
        <td className={styles.tableDataCheckbox()}>
          <label className={styles.containerCheckbox()}>
            <input
              id={`hs-table-pagination-checkbox-${data.id}`}
              name="checkbox"
              type="checkbox"
              className={styles.checkbox()}
              checked={isChecked}
              onChange={() => onSelectRow(data)}
              disabled={disableCheckbox}
            />

            <span className="sr-only">Checkbox</span>
          </label>
        </td>
        <td className={styles.tableData({ position: 0 })}>
          {data.DebitNote.numberDebitNote}

          <span className={styles.tableSubData()}>
            {format(new Date(data.DebitNote.issueDate), 'd/MM/yyyy')} (
            {t('issue')})
          </span>

          <span className={styles.tableSubData()}>
            {format(new Date(data.DebitNote.dueDate), 'd/MM/yyyy')} ({t('due')})
          </span>
        </td>
        <td className={styles.tableData({ position: 1 })}>
          {data.DebitNote.description}
          <span className={styles.tableSubData()}>
            {data.DebitNote.Sign.Deal.Plan.title}
          </span>

          <span className={styles.tableSubData()}>
            {t('type')} {data.DebitNote.debitType}
          </span>
        </td>
        <td className={styles.tableData({ position: 2 })}>
          {formatCurrency(data.DebitNote.totalAmount)}

          <span className={styles.tableSubData()}>
            {formatCurrency(data.DebitNote.netAmount)} ({t('net')})
          </span>

          <span className={styles.tableSubData()}>
            {taxes?.cofinsAmount && taxes?.pisAmount && (
              <>
                {formatCurrency(taxes.cofinsAmount)} ({t('confins')}) +{' '}
                {formatCurrency(taxes.pisAmount)} ({t('pis')})
              </>
            )}
          </span>
        </td>
        <td className={styles.tableData({ position: 3 }) + ' space-y-2'}>
          {data.DebitNote.hasPendingDebts && (
            <span
              className={
                styles.tableSubData() +
                ' py-1 bg-yellow-100 !text-yellow-600 !font-medium rounded-md px-2'
              }
            >
              {t('late')}
            </span>
          )}

          <SituationBadge status={data.DebitNote.status} />
        </td>
        <td className={styles.rowActions()}>
          <button
            type="button"
            className={styles.buttonItem()}
            onClick={() =>
              selectDebitNote(data.DebitNote as IDebitNotePaginated)
            }
            data-hs-overlay="#drawer-view-debit-note"
          >
            <Tooltip message={t('common:view')}>
              <FileSearch size={20} />
            </Tooltip>
          </button>
        </td>
      </td>

      {isChecked && (
        <td className={styles.formComment()}>
          <TextInput
            name={data.id}
            placeholder={t('invoicing/requestApprovalReview:comment')}
          />
        </td>
      )}
    </tr>
  );
}
