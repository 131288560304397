import { cva } from 'class-variance-authority';

export const Container = cva([
  'hs-overlay',
  'hidden',
  'w-full',
  'h-full',
  'fixed',
  'top-0',
  'left-0',
  'z-[49]',
  'overflow-x-hidden',
  'overflow-y-auto',
  '[--overlay-backdrop:static]',
  'hs-overlay-backdrop-open:bg-gray-900/50',
  'dark:hs-overlay-backdrop-open:bg-white/30',
]);

export const Wrapper = cva([
  'hs-overlay-open:mt-7',
  'hs-overlay-open:opacity-100',
  'hs-overlay-open:duration-500',
  'mt-0',
  'opacity-0',
  'ease-out',
  'transition-all',
  'w-max',
  'w-full',
  'm-3',
  'mx-auto',
]);

export const Content = cva([
  'relative',
  'flex',
  'flex-col',
  'bg-white',
  'dark:bg-chat-sidebar-bg',
  'shadow-lg',
  'rounded-xl',
  'w-max',
]);

export const Header = cva(['absolute', 'top-2', 'right-2']);

export const CloseButton = cva([
  'inline-flex',
  'flex-shrink-0',
  'justify-center',
  'items-center',
  'h-8',
  'w-8',
  'rounded-md',
  'text-gray-500',
  'hover:text-gray-400',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-gray-400',
  'focus:ring-offset-2',
  'focus:ring-offset-white',
  'transition-all',
  'text-sm',
]);

export const Body = cva([
  'p-4',
  'md:p-10',
  'text-center',
  'overflow-y-auto',
  'max-w-xs',
  'md:max-w-none',
]);

export const IconContainer = cva(
  [
    'mb-4',
    'inline-flex',
    'justify-center',
    'items-center',
    'w-[62px]',
    'h-[62px]',
    'rounded-full',
    'border-8',
    'border-spacing-10',
  ],
  {
    variants: {
      type: {
        danger: 'border-driver-centricity-50 bg-driver-centricity-100',
        warning: 'border-alert-50 bg-alert-100',
        primary: 'border-brand-50 bg-brand-100',
      },
    },
  },
);

export const AlertIcon = cva(['h-[26-x] w-[26px]']);

export const Title = cva([
  'mb-2',
  'text-2xl',
  'font-semibold',
  'text-neutral-800',
  'dark:text-neutral-200',
]);

export const Subtitle = cva([
  'mb-2',
  'text-base',
  'font-normal',
  'text-neutral-500',
  'dark:text-neutral-200',
]);

export const Actions = cva([
  'mt-6',
  'flex',
  'flex-col',
  'justify-center',
  'gap-2',
  'md:flex-row',
  'md:gap-x-4',
]);

export const CancelButton = cva([
  'py-[10px]',
  'px-3',
  'min-w-[160px]',
  'flex',
  'justify-center',
  'items-center',
  'gap-2',
  'rounded-md',
  'transition-all text-sm',
  'border',
  'shadow-sm',
]);

export const ConfirmButton = cva(
  [
    'py-[10px]',
    'px-3',
    'min-w-[160px]',
    'justify-center',
    'items-center',
    'gap-2',
    'rounded-md',
    'transition-all text-sm',
    'flex',
    'border',
    'shadow-sm',
  ],
  {
    variants: {
      type: {
        primary:
          'bg-brand-500 hover:bg-brand-400 focus:brand-300 text-white font-semibold',
        danger:
          'bg-driver-centricity-800 hover:bg-driver-centricity-600 focus:driver-centricity-500 text-white font-semibold',
        warning:
          'bg-alert-800 hover:bg-alert-100 focus:alert-100 text-white font-semibold',
      },
      isLoading: {
        true: ['cursor-wait'],
      },
    },
  },
);
