import { useUserStore } from '@/common/hooks/UserStore';

export enum LayoutItems {
  GENERATED_DEALS = 'generatedDeals',
  WINNER_DEALS = 'winnerDeals',
  LOSE_DEALS = 'loseDeals',
  MAIN_DEALS = 'mainDeals',
  RANKING_VEHICLES = 'rankingVehicles',
  RANKING_PLANS = 'rankingPlans',
  AVERAGE_TICKET = 'averageTicket',
  AVERAGE_CONTRACT_TIME = 'averageContractTime',
  AVERAGE_CLOSING_TIME = 'averageClosingTime',
  DISCOUNT_DEALS = 'discountDeals',
}

export enum LayoutSlots {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9',
  TEN = '10',
}

export type Layout = Record<LayoutSlots, LayoutItems>;

type DashboardLayout = {
  layout: Layout;
  saveLayout: (newLayout: Layout) => void;
};

export function useDashboardLayout(): DashboardLayout {
  const { user } = useUserStore();

  const DEFAULT_STATE: Layout = {
    [LayoutSlots.ONE]: LayoutItems.GENERATED_DEALS,
    [LayoutSlots.TWO]: LayoutItems.WINNER_DEALS,
    [LayoutSlots.THREE]: LayoutItems.LOSE_DEALS,
    [LayoutSlots.FOUR]: LayoutItems.AVERAGE_TICKET,
    [LayoutSlots.FIVE]: LayoutItems.DISCOUNT_DEALS,
    [LayoutSlots.SIX]: LayoutItems.AVERAGE_CONTRACT_TIME,
    [LayoutSlots.SEVEN]: LayoutItems.MAIN_DEALS,
    [LayoutSlots.EIGHT]: LayoutItems.RANKING_VEHICLES,
    [LayoutSlots.NINE]: LayoutItems.AVERAGE_CLOSING_TIME,
    [LayoutSlots.TEN]: LayoutItems.RANKING_PLANS,
  };

  const layout: Layout = localStorage.getItem(
    `@QTool:${user.id}-dashboardLayout`,
  )
    ? JSON.parse(
        localStorage.getItem(`@QTool:${user.id}-dashboardLayout`) as string,
      )
    : DEFAULT_STATE;

  const saveLayout = (newLayout: Layout) => {
    localStorage.setItem(
      `@QTool:${user.id}-dashboardLayout`,
      JSON.stringify(newLayout),
    );
  };

  return {
    layout,
    saveLayout,
  };
}
