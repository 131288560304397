import { SelectOption } from '@/@types/SelectOption';
import { CalendarDays, Trash } from 'lucide-react';
import { MouseEvent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Calendar } from '@/common/components/Calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/common/components/Popover';
import { useToast } from '@/common/hooks/useToast';

import * as style from './styles';

type DatePickerAlign = 'start' | 'center' | 'end' | undefined;

interface IDatePicker {
  align?: DatePickerAlign;
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  yearsOptions?: SelectOption<number>[];
  yesterdayDate?: boolean;
  onDateSelect?: (date: Date | undefined) => void;
}

export function DatePicker({
  align = 'start',
  name,
  label,
  disabled,
  placeholder,
  yearsOptions,
  yesterdayDate,
  onDateSelect,
}: IDatePicker) {
  const { control, watch, setValue, reset } = useFormContext();
  const date = watch(name) as Date | undefined | string;
  const [open, setOpen] = useState(false);

  const onRemoveDate = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setValue(name, undefined);
  };

  const { toast } = useToast();
  const { t } = useTranslation('common');

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (date instanceof Date && yesterdayDate) {
      const selectedDate = new Date(date);
      selectedDate.setHours(0, 0, 0, 0);

      if (selectedDate >= today) {
        toast.error(
          t('common:invalidDate'),
          t('common:dateGreaterThanTodayOrEqual', {
            fieldDate: t('startDate'),
          }),
        );
        reset({ [name]: null });
      }
    }
  }, [date]);

  const formatDate = (dateToFormat: string | Date) => {
    const dateObj =
      typeof dateToFormat === 'string' ? new Date(dateToFormat) : dateToFormat;
    return dateObj.toLocaleDateString('pt-BR');
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild disabled={disabled}>
        <button type="button" className={style.button()}>
          {label && <span className={style.label()}>{label}</span>}{' '}
          <div className={style.fakeInput({ isDisabled: disabled })}>
            <CalendarDays
              className={style.calendarIcon({ isDisabled: disabled })}
            />
            {date && <p>{formatDate(date)}</p>}{' '}
            {!date && placeholder && (
              <p className={style.placeholder()}>{placeholder}</p>
            )}
            {date && !disabled && (
              <button
                type="button"
                className={style.trashIcon()}
                onClick={onRemoveDate}
              >
                <Trash size={18} />
              </button>
            )}
          </div>
        </button>
      </PopoverTrigger>

      <PopoverContent className="w-auto p-0" align={align}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Calendar
              mode="single"
              selected={field.value}
              onSelect={selectedDate => {
                field.onChange(selectedDate);
                onDateSelect?.(selectedDate);
              }}
              onDayClick={() => setOpen(false)}
              yearsOptions={yearsOptions}
            />
          )}
        />
      </PopoverContent>
    </Popover>
  );
}
