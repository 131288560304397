import { cva } from 'class-variance-authority';

export const tableHead = cva([
  'border-t border-b border-neutral-200 min-w-full',
]);

export const column = cva<{ position: Record<number, string> }>(
  ['px-6 py-3', 'text-left font-medium text-xs text-gray-500'],
  {
    variants: {
      position: {
        0: 'w-1/4',
        1: 'w-1/4',
        2: 'w-1/4',
        3: 'w-1/4',
      },
    },
  },
);
