import * as style from './styles';

export function DashboardSkeleton() {
  return (
    <div className={style.container()}>
      <main className={style.main()}>
        <div className="flex w-full gap-4">
          <div className={style.card()}></div>

          <div className={style.card()}></div>

          <div className={style.card()}></div>
        </div>

        <div className="flex w-full gap-4">
          <div className={style.list()}></div>

          <div className={style.list()}></div>

          <div className="flex w-full flex-col gap-4">
            <div className={style.card()}></div>

            <div className={style.card()}></div>

            <div className={style.card()}></div>

            <div className={style.card()}></div>
          </div>
        </div>
      </main>
    </div>
  );
}
