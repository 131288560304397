import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addHours,
  addMinutes,
  secondsToHours,
  secondsToMinutes,
} from 'date-fns';
import flagsmith from 'flagsmith';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import { useAppRouter } from '@/common/hooks/useAppRouter';
import { useUserStore } from '@/common/hooks/UserStore';
import { httpClient } from '@/config/httpClient';
import { signInService } from '@/modules/login/services/signIn';
import { PublicRoutes } from '@/routes/public.routes';

import { SignInForm } from '../schemas/SignInSchema';

export type GetTokenCredentials = {
  username: string;
  password: string;
  grant_type: string;
};

export function useLogin() {
  const { mutateAsync: mutateSignIn, isLoading: isLoadingSignIn } =
    useMutation(signInService);
  const navigate = useNavigate();
  const { changeRouter } = useAppRouter();
  const { removeUser } = useUserStore();
  const queryClient = useQueryClient();

  const signIn = async (data: SignInForm) => {
    try {
      const credentials: GetTokenCredentials = {
        username: data.username,
        password: data.password,
        grant_type: 'password',
      };

      const response = await mutateSignIn(credentials);

      if (response) {
        const {
          access_token,
          expires_in,
          refresh_token,
          refresh_expires_in,
          session_state,
        } = response.data;

        httpClient.defaults.headers.Authorization = `Bearer ${access_token}`;

        if (!data.rememberData) return;

        Cookies.set('accessToken', access_token, {
          expires: addHours(new Date(), secondsToHours(expires_in)),
        });
        Cookies.set('refreshToken', refresh_token, {
          expires: addMinutes(new Date(), secondsToMinutes(refresh_expires_in)),
        });
        Cookies.set('sessionState', session_state);
      } else {
        throw {
          response: {
            data: {
              friendlyMessage: 'invalidCredentials',
            },
          },
        };
      }
    } catch (error) {
      throw error;
    }
  };

  const signOut = async () => {
    changeRouter(PublicRoutes);
    removeUser();
    navigate('/');

    flagsmith.logout();
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    Cookies.remove('sessionState');
    Cookies.remove('dealerId');
    Cookies.remove('accountId');
    Cookies.remove('storeId');
    Cookies.remove('role');
    Cookies.remove('userType');

    httpClient.defaults.headers.Authorization = '';
    queryClient.removeQueries();
  };

  return {
    signIn,
    isLoadingSignIn,
    signOut,
  };
}
