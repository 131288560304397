import { httpClient } from '@/config/httpClient';

export interface IResetPasswordPasswordPayload {
  password: string;
  verifyToken: string;
}

export async function resetPasswordService({
  verifyToken,
  ...payload
}: IResetPasswordPasswordPayload) {
  return httpClient.post('/user/reset-password', payload, {
    headers: {
      'verify-token': verifyToken,
    },
  });
}
