import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { RequestApprovalLayout } from '@/layouts/RequestApprovalLayout';

import RequestApproval from '../pages/RequestApproval';
import RequestApprovalReview from '../pages/RequestApprovalReview';
import RequestApprovalSuccess from '../pages/RequestApprovalSuccess';
import RequestApprovalValidateAccess from '../pages/RequestApprovalValidateAccess';

export const InvoicingPublicRoutes: RouteObject[] = [
  {
    element: <RequestApprovalLayout />,
    children: [
      {
        path: links.invoicing.requestApproval(),
        element: <RequestApproval />,
      },
      {
        path: links.invoicing.requestApprovalValidateAccess(),
        element: <RequestApprovalValidateAccess />,
      },
      {
        path: links.invoicing.requestApprovalReview(),
        element: <RequestApprovalReview />,
      },
      {
        path: links.invoicing.requestApprovalSuccess,
        element: <RequestApprovalSuccess />,
      },
    ],
  },
];
