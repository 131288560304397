import { HSPinInput } from 'preline/preline';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import * as style from './styles';

type PinInputProps = {
  name: string;
  availableCharsRE?: string;
  length?: number;
  size?: 'sm' | 'md' | 'lg';
  placeholder?: string;
  type?: 'text' | 'number' | 'password';
  disabled?: boolean;
};

export function PinInput({
  name,
  availableCharsRE = '',
  length = 3,
  size = 'md',
  placeholder = '⚬',
  type = 'text',
  disabled = false,
}: PinInputProps) {
  const { setValue, register } = useFormContext();

  useEffect(() => {
    window.HSStaticMethods.autoInit();

    const { element } = HSPinInput.getInstance(`#${name}`, true) as {
      element: null | HSPinInput;
    };

    if (!element) return;

    register(name);

    element?.on('completed', ({ currentValue }: { currentValue: string[] }) => {
      setValue(name, currentValue.join('').toUpperCase());
    });
  }, [name, register, setValue]);

  return (
    <div
      id={name}
      data-hs-pin-input={availableCharsRE}
      className={style.container()}
    >
      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          type={type}
          className={style.input({ size })}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={index === 0}
          data-hs-pin-input-item
          onPaste={e => e.preventDefault()}
        />
      ))}
    </div>
  );
}
