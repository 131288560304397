import { IBatchDebitNote } from '@/@types/BatchDebitNote';
import { zodResolver } from '@hookform/resolvers/zod';
import { Search } from 'lucide-react';
import { useEffect, useLayoutEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from '@/common/components/TextInput';
import {
  TableSearchForm,
  TableSearchSchema,
} from '@/modules/dealers/schemas/TableSearchSchema';

import * as styles from './styles';

type TableSearchProps = {
  onSearch: (search: string) => void;
  selectedBatchDebitNotes: IBatchDebitNote[];
};

export function TableSearch({
  onSearch,
  selectedBatchDebitNotes,
}: TableSearchProps) {
  const { t } = useTranslation(['invoicing/batchInvoices', 'common']);
  const hookForm = useForm<TableSearchForm>({
    mode: 'onSubmit',
    resolver: zodResolver(TableSearchSchema()),
  });

  const searchInputValue = hookForm.watch('search');

  useEffect(() => {
    onSearch(searchInputValue);
  }, [searchInputValue, onSearch]);

  useLayoutEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [selectedBatchDebitNotes.length]);

  return (
    <FormProvider {...hookForm}>
      <div className={styles.container()}>
        <form className={styles.content()}>
          <label htmlFor="search" className="sr-only">
            {t('search')}
          </label>

          <TextInput
            name="search"
            LeadingIcon={
              <Search
                className={styles.searchInputIcon()}
                size={18}
                strokeWidth={2.5}
              />
            }
            placeholder={t('common:search')}
          />
        </form>
      </div>
    </FormProvider>
  );
}
