import { httpClient } from '@/config/httpClient';

type PostValidateAccessBatchProps = {
  batchId: string;
  email: string;
  code: string;
};

export async function postValidateAccessBatch({
  batchId,
  email,
  code,
}: PostValidateAccessBatchProps) {
  return httpClient.post<string>('/batch/validate-access', {
    batchId,
    email,
    code,
  });
}
