import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { KeyRound, LogIn } from 'lucide-react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import QToolLogo from '@/assets/icons/qtool.svg';
import { Alert } from '@/common/components/Alert';
import { Checkbox } from '@/common/components/Checkbox';
import { Spinner } from '@/common/components/Spinner';
import { useToast } from '@/common/hooks/useToast';
import { links } from '@/common/utils/links';
import { ENV } from '@/config/env';
import i18n from '@/config/i18n';

import { useActivateUser } from '../../hooks/useActivateUser';
import {
  ActivateUserForm,
  ActivateUserSchema,
} from '../../schemas/ActivateUserSchema';

import * as style from './styles';

type JwtDecoded = {
  email: string;
  userId: string;
  locale: string;
  expirationDate: string;
};

export default function ActivateUser() {
  const [resetPasswordToken, setResetPasswordToken] = useState('');

  const { t } = useTranslation(['login/activateUser', 'common']);
  const {
    activateUser,
    isLoadingUserActivation,
    isSuccessActivateUser,
    isErrorActivateUser,
    error,
  } = useActivateUser();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('verify-token');
  const { toast } = useToast();
  const hookForm = useForm<ActivateUserForm>({
    resolver: zodResolver(ActivateUserSchema),
  });
  const isTermsAccepted = hookForm.watch('acceptTerms');

  useEffect(() => {
    if (!token || !isTermsAccepted) return;

    activateUser(token).then(response => {
      if (response?.data?.newPasswordToken) {
        setResetPasswordToken(response?.data?.newPasswordToken);
      }
    });
  }, [token, activateUser, isTermsAccepted]);

  useEffect(() => {
    const requestError = error as AxiosError;

    if (requestError && requestError?.response?.data?.friendlyMessage) {
      hookForm.setValue('acceptTerms', true);
      toast.warn(requestError?.response?.data?.friendlyMessage, null, {
        toastId: 'activate-user-error',
        autoClose: 8000,
      });
    }
  }, [error, hookForm, toast]);

  useEffect(() => {
    if (!token) return;
    const decoded = jwtDecode<JwtDecoded>(token);

    i18n.changeLanguage(decoded.locale);
  }, [token]);

  return (
    <div className={style.container()}>
      <div className={style.content()}>
        <header>
          <img src={QToolLogo} alt="QTool by IVECO" />
        </header>

        <main className={style.main({ isLoading: isLoadingUserActivation })}>
          <FormProvider {...hookForm}>
            {!isTermsAccepted && token && (
              <>
                <Alert
                  message={t('termsOfUse')}
                  description={
                    <span className={style.description()}>
                      {t('termsOfUseSubTitle')}
                    </span>
                  }
                  type="info"
                />

                <Checkbox label={t('acceptTerms')} name="acceptTerms" />
              </>
            )}
          </FormProvider>

          {isLoadingUserActivation && (
            <>
              <Alert
                message={t('activating')}
                description={
                  <span className={style.description()}>
                    {t('activatingDescription')}
                  </span>
                }
                type="info"
              />

              <Spinner size="xl" />
            </>
          )}

          {isErrorActivateUser && (
            <Alert
              message={t('errorActivate')}
              description={
                <span className={style.description()}>
                  {t('errorActivateDescription')}
                </span>
              }
              type="error"
            />
          )}

          {isSuccessActivateUser && (
            <>
              <Alert
                message={t('userActivated')}
                description={
                  <span className={style.description()}>
                    {t('userActivatedDescription')}
                  </span>
                }
                type="success"
              />

              <Link
                to={`${links.login.resetPassword}?verify-token=${resetPasswordToken}`}
                className={style.linkDoSignIn()}
              >
                <KeyRound />
                <span>
                  {((!token || isErrorActivateUser) && t('goToSignIn')) ||
                    t('definePassword')}
                </span>
              </Link>
            </>
          )}

          {!token && (
            <Alert
              message={t('tokenNotFound')}
              description={
                <span className={style.description()}>
                  {t('tokenNotFoundDescription')} <br />
                </span>
              }
              type="warning"
            />
          )}

          {(!token || isErrorActivateUser) && (
            <Link to="/" className={style.linkDoSignIn()}>
              <LogIn />
              <span>
                {((!token || isErrorActivateUser) && t('goToSignIn')) ||
                  t('doSignIn')}
              </span>
            </Link>
          )}
        </main>

        <footer className={style.footer()}>
          <a
            href={ENV.VITE_TERMS_OF_USE_URL}
            target="_bank"
            className={style.link()}
          >
            {t('common:termsOfUseAndPrivacyPolicy')}
          </a>

          <span className={style.copyright()}>{t('common:copyright')}</span>
        </footer>
      </div>
    </div>
  );
}
