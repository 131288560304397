import { cva } from 'class-variance-authority';

export const container = cva([
  'flex',
  'w-screen',
  'h-screen',
  'items-center',
  'justify-center',
  'py-16',
  'fixed',
  'left-0',
  'top-0',
  'z-50',
  'bg-white',
]);

export const content = cva([
  'w-[42.5rem]',
  'px-9',
  'h-full',
  'flex',
  'flex-col',
  'items-center',
  'justify-between',
]);

export const main = cva(
  ['flex', 'flex-col', 'gap-12', 'items-center', 'w-full'],
  {
    variants: {
      isLoading: {
        true: ['cursor-wait'],
      },
    },
  },
);

export const description = cva(['whitespace-pre']);

export const footer = cva(['flex', 'flex-col', 'gap-6', 'items-center']);

export const linkDoSignIn = cva([
  'w-2/4',
  'flex',
  'items-center',
  'justify-center',
  'gap-4',
  'bg-brand-pure',
  'text-white',
  'p-4',
  'rounded-md',
  'font-medium',
  'hover:bg-brand-400',
  'min-w-fit',
]);

export const link = cva([
  'font-inter',
  'text-base',
  'text-brand-400',
  'font-normal',
]);

export const copyright = cva([
  'font-inter',
  'font-light',
  'text-sm',
  'text-center',
  'text-neutral-400',
]);
