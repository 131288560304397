import { IAxiosError } from '@/@types/AxiosError';
import { ChevronDown } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BrFlag from '@/assets/icons/br-flag.svg';
import EsFlag from '@/assets/icons/es-flag.svg';
import UsFlag from '@/assets/icons/us-flag.svg';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '@/common/components/Dropdown';
import { useGetUserInfo } from '@/common/hooks/useGetUserInfo';
import { useToast } from '@/common/hooks/useToast';
import i18n from '@/config/i18n';
import { getUserLanguage } from '@/modules/userProfile/services/getUserLanguage';

import * as styles from './styles';

type LanguageMenuDropdownProps = {
  userId?: string;
};

export function LanguageMenuDropdown({ userId }: LanguageMenuDropdownProps) {
  const { t } = useTranslation('common');
  const { user, refetch, isLoadingUser } = useGetUserInfo();
  const { toast } = useToast();
  const [selectedLanguage, setSelectedLanguage] = useState<{
    label: string;
    value: string;
    icon: string;
  }>();

  const languageOptions = useMemo(() => {
    return [
      { label: t('Portuguese'), value: 'pt-BR', icon: BrFlag },
      { label: t('English'), value: 'en-US', icon: UsFlag },
      { label: t('Spanish'), value: 'es-ES', icon: EsFlag },
    ];
  }, [t]);

  useEffect(() => {
    setSelectedLanguage(
      userId
        ? languageOptions.find(
            option => option.value === user?.UserPreference?.locale,
          )
        : languageOptions.find(option => option.value === i18n.language),
    );
  }, [userId, user?.UserPreference?.locale, languageOptions]);

  const handleChangeLanguage = async (value: string) => {
    await i18n.changeLanguage(value);

    if (userId) {
      try {
        await getUserLanguage(userId);
        toast.success(t('common:languageChangedSuccessfully'));
      } catch (error) {
        const requestError = error as IAxiosError;
        toast.error(
          requestError?.response?.data?.friendlyMessage ||
            t('common:languageChangeError'),
        );
      } finally {
        refetch();
      }
    }

    setSelectedLanguage(languageOptions.find(option => option.value === value));
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        disabled={isLoadingUser}
        className={styles.dropButton()}
      >
        {selectedLanguage ? (
          <div className="flex flex-row items-center">
            <img
              className={styles.icon()}
              alt={`${selectedLanguage.label} flag`}
              src={selectedLanguage.icon}
            />
            <span className={styles.text()}>{selectedLanguage.label}</span>
          </div>
        ) : (
          <span className={styles.text()}>{t('SelectLanguage')}</span>
        )}

        <ChevronDown className={styles.icon()} size="20" />
      </DropdownMenuTrigger>

      <DropdownMenuContent className="min-w-[11.5rem]">
        <DropdownMenuRadioGroup
          value={selectedLanguage?.value}
          onValueChange={handleChangeLanguage}
        >
          {languageOptions?.map(option => (
            <DropdownMenuRadioItem
              key={option.value}
              className="px-4 py-2"
              value={option.value}
            >
              <img
                className={styles.icon()}
                alt={`${option.label} flag`}
                src={option.icon}
              />

              {option.label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
