import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Alert } from '@/common/components/Alert';

import * as style from './styles';

export default function RequestApprovalSuccess() {
  const { t } = useTranslation('invoicing/requestApprovalSuccess');
  const { state } = useLocation();

  return (
    <div className={style.container()}>
      <h1 className={style.title()}>
        {t('title', { type: t(state?.type || 'approved') })}
      </h1>

      <Alert
        type="success"
        message={t('successAlertMessage')}
        description={t('canClosePageAlertMessage')}
      />
    </div>
  );
}
