import { cva } from 'class-variance-authority';

export const TableHead = cva(['bg-gray-50', 'min-w-full']);

export const TableHeadCheckbox = cva(['py-3', 'px-4', 'pr-0']);

export const ContainerCheckbox = cva(['flex items-center', 'h-5']);

export const Checkbox = cva([
  'border-gray-200',
  'rounded',
  'text-blue-600',
  'focus:ring-blue-500',
  'cursor-pointer',
  'disabled:opacity-50 disabled:cursor-not-allowed',
]);

export const Column = cva<{ position: Record<number, string> }>(
  ['px-6', 'py-3', 'text-left', 'font-medium', 'text-xs', 'text-gray-500'],
  {
    variants: {
      position: {
        0: 'w-3/12',
        1: 'w-3/12',
        2: 'w-3/12',
        3: 'w-2/12',
        4: 'w-1/12',
      },
    },
  },
);
