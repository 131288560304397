import { cva } from 'class-variance-authority';

export const container = cva(['flex gap-4 overflow-y-auto']);

export const main = cva([
  'flex flex-col gap-4 justify-around pr-2 pb-2 w-full',
]);

export const card = cva([
  'w-full h-32 rounded-2xl bg-neutral-200 animate-pulse',
]);

export const list = cva([
  'w-full h-full rounded-2xl bg-neutral-200 animate-pulse',
]);
