import {
  IDealerExecutiveUser,
  IDealerStoreUser,
} from '@/@types/DealerStoreUsers';
import { IPerson } from '@/@types/Person';
import { IUser, IUserAccount, UserTypeEnum } from '@/@types/User';
import { IUserPreference } from '@/@types/UserPreference';

import { httpClient } from '../../config/httpClient';

type GetUserInfoBase = IUser & {
  lastPasswordChangeAt: string | null;
  isUserFirstLogin: boolean;
  changePasswordOnNextLogin: boolean;
  termOfUseAcceptedDate: string | null;
  termOfUseAcceptedIP: string | null;
  UserPreference: IUserPreference;
  Person: IPerson;
  isTermAccepted: boolean;
  accountId: string | null;
};

type GetUserInfoAccount = GetUserInfoBase & {
  userType: UserTypeEnum.ACCOUNT;
  UserAccount: IUserAccount[];
};

type GetUserInfoDealer = GetUserInfoBase & {
  userType: UserTypeEnum.DEALER;
  DealerStoreUsers: Array<IDealerExecutiveUser | IDealerStoreUser>;
};

export type GetUserInfo = GetUserInfoAccount | GetUserInfoDealer;

export function getUserInfo() {
  return httpClient.get<GetUserInfo>('/user/get-user-info');
}
