import { SelectOption } from '@/@types/SelectOption';
import { enUS, es, ptBR } from 'date-fns/locale';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import * as React from 'react';
import {
  DayPicker,
  FooterProps,
  useNavigation,
  Footer,
} from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { classMerge } from '@/common/utils';

import * as style from './styles';

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  yearsOptions?: SelectOption<number>[];
};

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  yearsOptions,
  ...props
}: CalendarProps) {
  const { t } = useTranslation('common');

  const locales: Record<string, Locale> = {
    'pt-BR': ptBR,
    'en-US': enUS,
    en: enUS,
    es,
  };

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={classMerge(style.dayPicker(), className)}
      classNames={{
        months: style.months(),
        month: style.month(),
        caption: style.caption(),
        caption_label: style.captionLabel(),
        nav: style.nav(),
        nav_button: classMerge(style.navButton()),
        nav_button_previous: style.navButtonPrevious(),
        nav_button_next: style.navButtonNext(),
        table: style.table(),
        head_row: style.headRow(),
        head_cell: style.headCell(),
        row: style.row(),
        cell: style.cell(),
        day: style.day(),
        day_selected: style.daySelected(),
        day_today: style.dayToday(),
        day_outside: style.dayOutside(),
        day_disabled: style.dayDisabled(),
        day_range_middle: style.dayRangeMiddle(),
        day_hidden: style.dayHidden(),
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className={style.iconLeft()} />,
        IconRight: () => <ChevronRight className={style.iconRight()} />,
        Footer: (footerProps: FooterProps) => {
          const { displayMonth } = footerProps;
          const { goToDate } = useNavigation();

          if (!yearsOptions) {
            return <Footer {...footerProps} />;
          }

          return (
            <div className="border-t border-gray-200 pt-2">
              <Select
                name="color"
                options={yearsOptions}
                placeholder={t('navigateToYear')}
                noOptionsMessage={() => t('noOptions')}
                onChange={option => {
                  goToDate(
                    new Date(
                      `${displayMonth!.getMonth()}/${new Date().getDate()}/${
                        option!.value
                      }`,
                    ),
                  );
                }}
              />
            </div>
          );
        },
      }}
      locale={locales[navigator.language] || ptBR}
      {...props}
    />
  );
}

Calendar.displayName = 'Calendar';

export { Calendar };
