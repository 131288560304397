export function parseJWT<T>(jwt?: string): T | null {
  try {
    if (!jwt) return null;

    const parts = jwt.split('.');

    if (parts.length !== 3) {
      return null;
    }

    const payload = parts[1];
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Erro ao fazer parse do token JWT:', error);
    return null;
  }
}
