import { IBatch } from '@/@types/Batch';
import { IBatchDebitNote } from '@/@types/BatchDebitNote';
import {
  ISignChassis,
  SalesModality,
  SignPersonPartEnum,
} from '@/@types/Contracts';
import { ICustomer } from '@/@types/Customer';
import { DebitNoteStatus, IDebitNote } from '@/@types/DebitNote';
import { IDebitNoteSignChassisInstallments } from '@/@types/DebitNoteSignChassisInstallments';
import { IPagination } from '@/@types/Pagination';
import { ISign } from '@/@types/Sign';
import { ISignChassisInstallments } from '@/@types/SignChassisInstallments';
import { ISignPerson } from '@/@types/SignPerson';
import Cookies from 'js-cookie';

import { httpClient } from '@/config/httpClient';

export type IBatchDebitNotePaginated = IBatchDebitNote & {
  Batch: IBatch;
  DebitNote: {
    Customer: ICustomer;
    DebitNoteSignChassisInstallments: ({
      SignChassisInstallments: ISignChassisInstallments & {
        SignChassis: ISignChassis;
      };
    } & IDebitNoteSignChassisInstallments)[];
    Sign: ISign & {
      SignPerson: [ISignPerson];
      id: string;
      Deal: {
        id: string;
        Plan: {
          id: string;
          title: string;
        };
      };
    };
  } & IDebitNote;
};

export async function getInfiniteBatchDebitNote(
  batchId: string,
  page = 1,
  freeSearch?: string,
  salesModality?: SalesModality | null,
  period?: string,
  status?: DebitNoteStatus[],
) {
  const where: Record<string, any> = {
    isActive: true,
    batchId,
  };

  if (freeSearch) {
    Object.assign(where, { OR: [] });

    where.OR.push({ DebitNote: { description: { contains: freeSearch } } });
    where.OR.push({ DebitNote: { debitType: { contains: freeSearch } } });
    where.OR.push({ DebitNote: { codeDebitNote: { contains: freeSearch } } });
  }

  if (salesModality) {
    Object.assign(where, { DebitNote: { salesModality } });
  }

  if (period) {
    Object.assign(where, { DebitNote: { accrualPeriod: period } });
  }

  if (status && status.length) {
    Object.assign(where, { DebitNote: { status: { in: status } } });
  }

  return httpClient.get<IPagination<IBatchDebitNotePaginated>>(
    '/batch-debit-note/paginate-request',
    {
      headers: {
        Authorization: `Bearer ${Cookies.get('jwt-access-batch')}`,
      },
      params: {
        page,
        limit: 15, // Fixed in this value for performance reasons
        relations: {
          where,
          include: {
            Batch: true,
            DebitNote: {
              include: {
                Customer: true,
                DebitNoteSignChassisInstallments: {
                  distinct: ['debitNoteId'],
                  include: {
                    SignChassisInstallments: {
                      include: {
                        SignChassis: true,
                      },
                    },
                  },
                },
                Sign: {
                  include: {
                    SignPerson: {
                      where: { part: SignPersonPartEnum.COMPRADOR },
                    },
                    Deal: {
                      select: {
                        Plan: {
                          select: {
                            id: true,
                            title: true,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  );
}
