import { LucideIcon, X } from 'lucide-react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import AlertTriangleDanger from '@/assets/icons/alert-triangle-danger.svg';
import AlertTrianglePrimary from '@/assets/icons/alert-triangle-primary.svg';
import AlertTriangleWarning from '@/assets/icons/alert-triangle-warning.svg';

import { Spinner } from '../Spinner';

import * as style from './styles';

type AlertModalProps = {
  id: string;
  title: string;
  subTitle?: string;
  description?: ReactNode;
  cancelText?: string;
  confirmText?: string;
  confirmIcon?: LucideIcon;
  onConfirm?: () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  type: 'danger' | 'warning' | 'info' | 'primary';
  confirmType?: 'primary' | 'warning' | 'danger';
  isLoading?: boolean;
  showCancelIcon?: boolean;
};

export function AlertModal({
  id,
  title,
  subTitle,
  description,
  cancelText,
  confirmText,
  confirmIcon: ConfirmIcon,
  type,
  confirmType,
  onConfirm,
  onSubmit,
  onCancel,
  isLoading,
  showCancelIcon = true,
}: AlertModalProps) {
  const { t } = useTranslation('common');

  const iconByType = {
    danger: AlertTriangleDanger,
    warning: AlertTriangleWarning,
    primary: AlertTrianglePrimary,
  };

  return (
    <div
      id={`hs-modal-${id}`}
      className={style.Container()}
      data-hs-overlay-keyboard="false"
    >
      <div className={style.Wrapper()}>
        <div className={style.Content()}>
          <div className={style.Header()}>
            <button
              type="button"
              className={style.CloseButton()}
              data-hs-overlay={`#hs-modal-${id}`}
              onClick={onCancel}
            >
              <span className="sr-only">{t('close')}</span>
              <X size="24" strokeWidth={1.5} />
            </button>
          </div>

          <div className={style.Body()}>
            {type !== 'info' && (
              <span className={style.IconContainer({ type })}>
                <img
                  src={iconByType[type]}
                  alt={t('alertSymbol')}
                  className={style.AlertIcon()}
                />
              </span>
            )}

            <h3 className={style.Title()}>{title}</h3>
            <p className={style.Subtitle()}>{subTitle}</p>

            {description}
            {type !== 'info' && (
              <div className={style.Actions()}>
                <button
                  disabled={isLoading}
                  type="button"
                  className={style.CancelButton()}
                  data-hs-overlay={`#hs-modal-${id}`}
                  onClick={onCancel}
                >
                  {showCancelIcon && <X size={18} />}
                  {cancelText}
                </button>

                <button
                  disabled={isLoading}
                  type="button"
                  className={style.ConfirmButton({
                    type: confirmType || type,
                    isLoading,
                  })}
                  onClick={() => {
                    if (onConfirm) {
                      onConfirm();
                    }
                    if (onSubmit) {
                      onSubmit();
                    }
                  }}
                >
                  {ConfirmIcon && !isLoading && <ConfirmIcon size={18} />}
                  {!isLoading && confirmText}
                  {isLoading && <Spinner variant="white" />}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
