import { useMutation } from '@tanstack/react-query';

import { postActivateUser } from '../services/postActivateUser';

export function useActivateUser() {
  const {
    mutateAsync: activateUser,
    isLoading: isLoadingUserActivation,
    isSuccess,
    isError,
    error,
  } = useMutation({
    mutationFn: postActivateUser,
  });

  return {
    activateUser,
    isLoadingUserActivation,
    isSuccessActivateUser: isSuccess,
    isErrorActivateUser: isError,
    error,
  };
}
