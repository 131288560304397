import { cva } from 'class-variance-authority';

export const container = cva([
  'flex flex-col flex-1 items-center justify-center gap-4',
  'max-w-screen-2xl h-full',
  'overflow-y-auto',
]);

export const info = cva(['flex flex-col items-center justify-center gap-4']);

export const title = cva(['text-3xl font-bold text-center text-neutral-900']);

export const alertDescription = cva([
  'flex flex-col items-start justify-center gap-2',
  'max-w-md',
]);

export const tableContainer = cva([
  'border rounded-lg',
  'divide-y divide-gray-200',
  'bg-white shadow',
  'flex flex-col w-full h-full',
]);

export const table = cva([
  'divide-y divide-gray-200',
  'flex flex-col flex-1 max-h-[calc(100vh-524px)] w-full',
]);

export const tableBody = cva([
  'bg-white rounded-b-lg',
  'divide-y divide-gray-200',
  'flex-1 overflow-auto w-full',
]);

export const actions = cva([
  'flex flex-row-reverse flex-1 items-start justify-between gap-4',
  'w-full',
]);

export const form = cva(['flex flex-col items-end justify-center gap-2']);
