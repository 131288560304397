import { LucideIcon, X } from 'lucide-react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useToast } from '@/common/hooks/useToast';

import { Badge, BadgeProps } from '../Badge';

import { SkeletonHeader } from './components/SkeletonHeader';
import * as style from './styles';

export interface IDrawerProps {
  id: string;
  title?: string;
  icon?: LucideIcon;
  subTitle?: ReactNode;
  children: ReactNode;
  leftActions?: ReactNode;
  rightActions?: ReactNode;
  commentsArea?: ReactNode;
  dispensable?: boolean;
  badge?: {
    title: string;
    type: BadgeProps['type'];
    icon?: ReactNode;
  } | null;
  iconClickable?: boolean;
  iconText?: string;
  toastText?: string;
  withDivider?: boolean;
}

/**
 * @param {string} id - Used to open/close the drawer by data-hs-overlay attribute
 */
export function Drawer({
  id,
  title,
  icon: Icon,
  subTitle,
  children,
  leftActions,
  rightActions,
  commentsArea,
  dispensable = true,
  badge,
  iconClickable = false,
  iconText,
  toastText,
  withDivider = true,
}: IDrawerProps) {
  const { t } = useTranslation('common');
  const { toast } = useToast();

  const handleIconClick = () => {
    if (iconText) {
      navigator.clipboard.writeText(iconText);
      toast.success(t('common:copiedToClipboardMessage', { field: toastText }));
    }
  };

  return (
    <div
      id={id}
      className={style.Container({ dispensable })}
      data-hs-overlay-keyboard={dispensable}
    >
      <div className={style.Content()}>
        <div className={style.Header()}>
          <div className={style.WrapperTitle()}>
            <div className={style.Title()}>
              {title && <h1 className={style.TitleText()}>{title}</h1>}
              {!title && <SkeletonHeader />}

              {Icon && (
                <Icon
                  size={24}
                  className={`text-brand-pure hs-dropdown-toggle hidden md:block ${
                    iconClickable ? 'cursor-pointer' : ''
                  }`}
                  onClick={iconClickable ? handleIconClick : undefined}
                />
              )}
            </div>

            <div className={style.CloseContainer()}>
              {badge && (
                <Badge
                  title={badge.title}
                  type={badge.type}
                  LeadingIcon={badge.icon}
                />
              )}

              <button
                className={style.Close()}
                type="button"
                data-hs-overlay={`#${id}`}
              >
                <X size={24} className={style.CloseIcon({ brand: true })} />
              </button>
            </div>
          </div>

          {subTitle && (
            <span className="pl-1 text-neutral-700">{subTitle}</span>
          )}

          {withDivider && <div className={style.Divider()} />}
        </div>

        <div className={style.Main()}>{children}</div>

        {commentsArea}

        {(rightActions || leftActions) && (
          <div className={style.Footer()}>
            <div className={style.Divider()} />

            <div className={style.ActionsContainer()}>
              <div className={style.Actions()}>{leftActions}</div>

              <div className={style.Actions()}>{rightActions}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
