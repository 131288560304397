import { cva } from 'class-variance-authority';

export const container = cva(['flex', 'w-full', 'h-full', 'flex-col', 'p-2']);

export const content = cva(['flex', 'w-full', 'h-full', 'flex', 'gap-4']);

export const main = cva([
  'xl:h-[calc(100vh-4rem)]',
  'h-[calc(100vh-18rem)]',
  'w-full overflow-y-auto',
  'flex flex-col gap-4 pr-2 pb-2',
]);

export const slot = cva([
  'w-full rounded-2xl data-[swapy-highlighted]:bg-neutral-100',
]);

export const buttonIA = cva([
  'flex',
  'items-center',
  'justify-center',
  'shadow-default',
  'hover:transition-all',
  'cursor-pointer',
  'rounded-md',
  'transition-all',
  'w-full',
  'group',
  'min-w-max',
  'p-2.5',
  'text-sm',
  'gap-1',
  'font-medium',
  'text-white',
  'bg-brand-pure',
  'enabled:hover:bg-brand-600',
]);

export const buttonIAText = cva([
  'hidden',
  'group-hover:inline',
  'opacity-0',
  'group-hover:opacity-100',
  'transition-opacity',
  'duration-300',
]);
