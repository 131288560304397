import { IPlanRanking } from '@/@types/Plan';
import { useFlags } from 'flagsmith/react';
import { CircleDollarSign, GripVertical } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { formatCurrency } from '@/common/utils/formatCurrency';
import i18n from '@/config/i18n';

import * as style from './styles';

type RankingPlansProps = {
  data: IPlanRanking[];
};

export function RankingPlans({ data }: RankingPlansProps) {
  const { t } = useTranslation('panel/panel');
  const { enabled_swapy_dashboard } = useFlags(['enabled_swapy_dashboard']);

  return (
    <div className={style.container()} data-swapy-item="rankingPlans">
      {enabled_swapy_dashboard.enabled && (
        <div data-swapy-handle className={style.handle()}>
          <GripVertical className={style.handleIcon()} />
        </div>
      )}

      <div className={style.titleContainer()}>
        <CircleDollarSign className={style.icon()} />

        <p className={style.title()}>{t('rankingPlans')}</p>
      </div>

      <ul className={style.list()}>
        {data.map((plan, index) => (
          <li className={style.listItem()} key={plan.planId}>
            <div className={style.subject()}>
              <span className={style.position()}>{index + 1}º</span>

              <div className={style.info()}>
                <span className={style.name()}>{plan.title}</span>
              </div>
            </div>

            <div className={style.values()}>
              <span className={style.subData()}>
                {t('totalPlans')} <b>{plan.quantity}</b>
              </span>

              <span className={style.total()}>
                {t('total')} <b>{formatCurrency(plan.totalAmount)}</b>
              </span>

              <span className={style.rateConversion()}>
                (
                <b>
                  {Intl.NumberFormat(i18n.language, {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(plan.conversionRate * 100)}
                  %
                </b>{' '}
                {t('totalConversion')})
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
