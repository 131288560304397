import { zodResolver } from '@hookform/resolvers/zod';
import Cookies from 'js-cookie';
import { useCallback, useLayoutEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import Button from '@/common/components/Button';
import { PinInput } from '@/common/components/PinInput';
import { Spinner } from '@/common/components/Spinner';
import { useToast } from '@/common/hooks/useToast';
import { links } from '@/common/utils/links';

import { useValidateAccessBatch } from '../../hooks/useValidateAccessBatch';
import {
  RequestApprovalValidateAccessForm,
  RequestApprovalValidateAccessSchema,
} from '../../schemas/RequestApprovalValidateAccessSchema';

import * as style from './styles';

export default function RequestApprovalValidateAccess() {
  const { t } = useTranslation('invoicing/requestApprovalValidateAccess');
  const hookForm = useForm<RequestApprovalValidateAccessForm>({
    resolver: zodResolver(RequestApprovalValidateAccessSchema),
    defaultValues: {
      code: '',
    },
  });
  const { batchId } = useParams();
  const { validateAccessBatch, isValidateAccessBatchLoading } =
    useValidateAccessBatch();
  const { toast } = useToast();
  const navigate = useNavigate();

  const emailFromCookies = Cookies.get('email-access-batch');
  const code = hookForm.watch('code');

  const onSubmit = useCallback(
    async (data: RequestApprovalValidateAccessForm) => {
      await validateAccessBatch({
        ...data,
        batchId: batchId!,
        email: emailFromCookies!,
      });
    },
    [batchId, emailFromCookies, validateAccessBatch],
  );

  useLayoutEffect(() => {
    if (!code) return;

    const buttonSubmit = document.getElementById(
      'button-submit-code-form',
    ) as HTMLButtonElement;

    buttonSubmit.click();
  }, [code]);

  if (!emailFromCookies) {
    toast.warn(t('identityNotFoundTitle'), t('identityNotFoundDescription'), {
      autoClose: 6000,
      toastId: 'identity-not-found',
    });

    return <Navigate to={links.invoicing.requestApproval(batchId!)} />;
  }

  return (
    <div className={style.container()}>
      <div className={style.content()}>
        <div className={style.info()}>
          <h1 className={style.title()}>{t('title')}</h1>

          <p className={style.description()}>
            {t('description')}
            <br />
            {t('subDescription')}
          </p>
        </div>

        <FormProvider {...hookForm}>
          <form
            id="code-form"
            onSubmit={hookForm.handleSubmit(onSubmit)}
            className={style.form()}
          >
            <PinInput name="code" length={6} />

            <Spinner
              size="lg"
              className={isValidateAccessBatchLoading ? '' : 'invisible'}
            />

            <button
              id="button-submit-code-form"
              className="hidden"
              type="submit"
            />
          </form>
        </FormProvider>
      </div>

      <Button
        variant="ghost"
        onClick={() => navigate(links.invoicing.requestApproval(batchId!))}
        text={t('back')}
        className="w-fit"
      />
    </div>
  );
}
