import { useUserStore } from '../hooks/UserStore';

export function formatCurrency(
  value: number,
  options?: Intl.NumberFormatOptions,
): string {
  const { userAccount } = useUserStore.getState();
  const country = userAccount?.Account?.country || 'BR';

  const currencyMatch: Record<string, [string, string]> = {
    BR: ['pt-BR', 'BRL'],
    US: ['en-US', 'USD'],
    AR: ['es-AR', 'USD'],
    CO: ['es-CO', 'COP'],
    IT: ['it-IT', 'EUR'],
    EC: ['es-EC', 'USD'],
  };

  const [locale, currency] = currencyMatch[country];

  return new Intl.NumberFormat(locale, {
    ...options,
    style: 'currency',
    currency,
  }).format(value);
}
