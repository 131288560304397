import { httpClient } from '@/config/httpClient';

export interface IRecoveryPasswordPayload {
  email: string;
}

export async function postRecoveryPasswordService({
  ...payload
}: IRecoveryPasswordPayload) {
  return httpClient.post('/user/forgot-password', payload);
}
