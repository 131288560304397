import { SalesModality } from './Contracts';

export enum DebitNoteStatus {
  FIRST_INSTALLMENT = 'FIRST_INSTALLMENT',
  LAST_INSTALLMENT = 'LAST_INSTALLMENT',
  REGULAR = 'REGULAR',
  CANCELLED = 'CANCELLED',
  ADJUSTMENT = 'ADJUSTMENT',
}

export interface IDebitNote {
  id: string;
  signId: string;
  customerId: string;
  accountId: string;
  responsibleId: string;
  numberDebitNote: string;
  codeDebitNote: string;
  description: string | null;
  accrualPeriod: string;
  issueDate: string;
  dueDate: string;
  totalAmount: number;
  netAmount: number;
  taxRate: number;
  taxAmount: number;
  debitType: string;
  status: DebitNoteStatus;
  hasPendingDebts: true;
  salesModality: SalesModality;
  metadata: Record<string, any>;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}
