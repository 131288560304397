import { cva } from 'class-variance-authority';

export const dayPicker = cva(['py-4', 'px-6']);

export const months = cva([
  'flex',
  'flex-col',
  'sm:flex-row',
  'space-y-4',
  'sm:space-x-4',
  'sm:space-y-0',
]);

export const month = cva(['space-y-4']);

export const caption = cva([
  'flex',
  'justify-center',
  'pt-1',
  'relative',
  'items-center',
]);

export const captionLabel = cva([
  'font-inter',
  'text-lg',
  'font-medium',
  'text-neutral-800',
]);

export const nav = cva(['space-x-1', 'flex', 'items-center']);

export const navButton = cva([
  'h-7',
  'w-7',
  'bg-transparent',
  'p-0',
  'opacity-50',
  'hover:opacity-100',
]);

export const navButtonPrevious = cva(['absolute', 'left-1']);

export const navButtonNext = cva(['absolute', 'right-1']);

export const table = cva(['w-full', 'border-collapse', 'space-y-1']);

export const headRow = cva(['flex']);

export const headCell = cva([
  'text-neutral-500',
  'rounded-full',
  'w-9',
  'font-inter',
  'font-medium',
  'text-sm',
]);

export const row = cva(['flex', 'w-full', 'mt-2']);

export const cell = cva([
  'text-center',
  'text-sm',
  'p-0',
  'relative',
  '[&:has([aria-selected])]:bg-neutral-100',
  'first:[&:has([aria-selected])]:rounded-l-full',
  'last:[&:has([aria-selected])]:rounded-r-full',
  'focus-within:relative',
  'focus-within:z-20',
]);

export const day = cva([
  'h-9',
  'w-9',
  'p-0',
  'font-normal',
  'aria-selected:opacity-100',
  'rounded-lg',
]);

export const daySelected = cva([
  'bg-brand-400',
  'text-neutral-50',
  'hover:bg-brand-400',
  'hover:text-neutral-50',
  'focus:bg-brand-400',
  'focus:text-neutral-50',
]);

export const dayToday = cva(['bg-neutral-200', 'text-neutral-900']);

export const dayOutside = cva(['text-neutral-500', 'opacity-50']);

export const dayDisabled = cva(['text-neutral-500', 'opacity-50']);

export const dayRangeMiddle = cva([
  'aria-selected:bg-neutral-100',
  'aria-selected:text-neutral-900',
]);

export const dayHidden = cva(['invisible']);

export const iconLeft = cva(['h-5', 'w-5']);

export const iconRight = cva(['h-5', 'w-5']);
