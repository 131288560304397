import { IAxiosError } from '@/@types/AxiosError';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useUserStore } from '@/common/hooks/UserStore';
import { useToast } from '@/common/hooks/useToast';

import { getDashboardKPIs } from '../services/getDashboardKPIs';

export function useDashboardKPIs(
  freeSearch?: string,
  startDate?: string | null,
  endDate?: string | null,
  tagsSearch?: string,
  storeSearch?: string,
  sellerSearch?: string,
  productSearch?: string,
  locality?: string,
) {
  const { dealerId, storeId, userType, role, user, accountId } = useUserStore();
  const { toast } = useToast();
  const { t } = useTranslation('panel/panel');

  const { data, isLoading, isError, refetch } = useQuery(
    [
      'dashboardKPIs',
      userType,
      role,
      user.id,
      storeId,
      dealerId,
      accountId,
      freeSearch,
      startDate,
      endDate,
      tagsSearch,
      storeSearch,
      sellerSearch,
      productSearch,
      locality,
    ],
    () =>
      getDashboardKPIs(
        userType,
        role,
        user.id,
        storeId,
        dealerId,
        accountId,
        freeSearch,
        startDate,
        endDate,
        tagsSearch,
        storeSearch,
        sellerSearch,
        productSearch,
        locality,
      ),
    {
      enabled: !!userType,
      staleTime: 1000 * 60 * 60, // 1 hour
      onError: (errorRequest: IAxiosError) => {
        toast.error(
          errorRequest?.response?.data?.friendlyMessage || t('errorGetKIPIs'),
        );
      },
    },
  );

  return {
    kpis: data?.data,
    isLoadingKPIs: isLoading,
    errorKPIs: isError,
    refetchKPIs: refetch,
  };
}
