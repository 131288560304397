import { zodResolver } from '@hookform/resolvers/zod';
import { differenceInDays, format, subDays } from 'date-fns';
import { useFlags } from 'flagsmith/react';
import { Filter } from 'lucide-react';
import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createSwapy, Swapy } from 'swapy';

import AIIcon from '@/assets/icons/ai-icon.svg?react';
import iconAverageClosingTime from '@/assets/icons/average-closing-time-icon.svg';
import iconAverageContractTime from '@/assets/icons/average-contract-time-icon.svg';
import iconAverageTicket from '@/assets/icons/average-ticket-icon.svg';
import iconDiscountDeals from '@/assets/icons/discounted-deals-icon.svg';
import iconGeneratedDeals from '@/assets/icons/generated-deals-icon.svg';
import iconLoseDeals from '@/assets/icons/lost-deals-icon.svg';
import iconWinnerDeals from '@/assets/icons/winner-deals-icon.svg';
import Button from '@/common/components/Button';
import { DealKPICard } from '@/common/components/DealKPICard';
import { FiltersHeader } from '@/common/components/FiltersHeader';
import { MainDeals } from '@/common/components/MainDeals';
import { RankingPlans } from '@/common/components/RankingPlans';
import { RankingVehicles } from '@/common/components/RankingVehicles';
import { useUserStore } from '@/common/hooks/UserStore';
import { formatCurrency } from '@/common/utils/formatCurrency';
import { links } from '@/common/utils/links';
import i18n from '@/config/i18n';

import { useDashboardFilters } from '../../hooks/useDashboardFilters';
import { useDashboardKPIs } from '../../hooks/useDashboardKPIs';
import {
  Layout,
  LayoutItems,
  useDashboardLayout,
} from '../../hooks/useDashboardLayout';
import {
  DashboardFilterForm,
  DashboardFilterSchema,
} from '../../schemas/DashboardFilterSchema';

import { DashboardSkeleton } from './components/DashboardSkeleton';
import { DrawerDashboardFilters } from './components/DrawerDashboardFilters';
import * as style from './styles';

export default function Panel() {
  const { t } = useTranslation('panel/panel');
  const swapy = useRef<Swapy | null>(null);
  const container = useRef<HTMLDivElement>(null);
  const hookForm = useForm<DashboardFilterForm>({
    resolver: zodResolver(DashboardFilterSchema),
    defaultValues: {
      startDate: subDays(new Date(), 30).toISOString(),
      endDate: new Date().toISOString(),
    },
  });
  const { enabled_swapy_dashboard } = useFlags(['enabled_swapy_dashboard']);
  const { layout, saveLayout } = useDashboardLayout();
  const { person, userType } = useUserStore();

  const {
    freeSearch,
    startDate,
    endDate,
    tagsSearch,
    storeSearch,
    sellerSearch,
    productSearch,
    locality,
    resetDashboardFilters,
    resetDashboardFreeSearch,
    updateDashboardFilters,
  } = useDashboardFilters();
  const { kpis, isLoadingKPIs } = useDashboardKPIs(
    freeSearch,
    startDate,
    endDate,
    tagsSearch,
    storeSearch,
    sellerSearch,
    productSearch,
    locality,
  );

  const handleClearFilters = () => {
    resetDashboardFilters();
  };

  const handleClearFreeSearch = () => {
    resetDashboardFreeSearch();
  };

  const resetOrKeepValue = (
    filter: string,
    field: keyof DashboardFilterForm,
    value?: string | null,
  ) => {
    if (filter === value) {
      hookForm.setValue(field, '');
      return '';
    }

    return value || '';
  };

  const handleRemoveFilter = (filter: string) => {
    const newStartDate = startDate
      ? format(new Date(startDate), 'dd/MM/yyyy')
      : null;
    const newEndDate = endDate ? format(new Date(endDate), 'dd/MM/yyyy') : null;
    const dateBadges =
      startDate && endDate ? `${newStartDate} - ${newEndDate}` : null;

    if (filter === t('last30Days')) {
      hookForm.setValue('startDate', null);
      hookForm.setValue('endDate', null);

      updateDashboardFilters({
        startDate: null,
        endDate: null,
      });
      return;
    }

    updateDashboardFilters({
      freeSearch: resetOrKeepValue(filter, 'freeSearch', freeSearch),
      storeSearch: resetOrKeepValue(filter, 'storeSearch', storeSearch),
      tagsSearch: resetOrKeepValue(filter, 'tagsSearch', tagsSearch),
      sellerSearch: resetOrKeepValue(filter, 'sellerSearch', sellerSearch),
      productSearch: resetOrKeepValue(filter, 'productSearch', productSearch),
      startDate: dateBadges === filter ? null : startDate,
      endDate: dateBadges === filter ? null : endDate,
      locality: resetOrKeepValue(filter, 'locality', locality),
    });
  };

  const getCardBySlot = (card: LayoutItems) => {
    const MAP_CARD_TO_COMPONENT: Record<LayoutItems, JSX.Element> = {
      generatedDeals: (
        <DealKPICard
          id="generatedDeals"
          direction="column"
          icon={iconGeneratedDeals}
          description={t('generatedDeals')}
          value={kpis?.generatedDeals.quantity || 0}
          extraInfo={[
            {
              label: t('totalValueOf'),
              value: formatCurrency(kpis?.generatedDeals.totalAmount || 0),
            },
            {
              label: t('totalOfProducts'),
              value: kpis?.generatedDeals.quantityOfProducts || 0,
            },
          ]}
        />
      ),
      winnerDeals: (
        <DealKPICard
          id="winnerDeals"
          direction="column"
          icon={iconWinnerDeals}
          description={t('winnerDeals')}
          value={kpis?.winnerDeals.quantity || 0}
          extraInfo={[
            {
              label: t('conversionRate'),
              value:
                Intl.NumberFormat(i18n.language, {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format((kpis?.winnerDeals.conversionRate || 0) * 100) + '%',
            },
            {
              label: t('totalValueOf'),
              value: formatCurrency(kpis?.winnerDeals.totalAmount || 0),
            },
          ]}
        />
      ),
      loseDeals: (
        <DealKPICard
          id="loseDeals"
          direction="column"
          icon={iconLoseDeals}
          description={t('loseDeals')}
          value={kpis?.lostDeals.quantity || 0}
          extraInfo={[
            {
              label: t('churn'),
              value:
                Intl.NumberFormat(i18n.language, {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format((kpis?.lostDeals.conversionRate || 0) * 100) + '%',
            },
            {
              label: t('totalValueOf'),
              value: formatCurrency(kpis?.lostDeals.totalAmount || 0),
            },
          ]}
        />
      ),
      averageTicket: (
        <DealKPICard
          id="averageTicket"
          icon={iconAverageTicket}
          description={t('averageTicket')}
          value={formatCurrency(kpis?.avgWinnerDealAmount || 0)}
        />
      ),
      discountDeals: (
        <DealKPICard
          id="discountDeals"
          icon={iconDiscountDeals}
          description={t('discountDeals')}
          value={kpis?.discountedDeals.quantity || 0}
          extraInfo={[
            {
              label: t('totalDiscount'),
              value: formatCurrency(kpis?.discountedDeals.totalAmount || 0),
            },
          ]}
        />
      ),
      averageContractTime: (
        <DealKPICard
          id="averageContractTime"
          icon={iconAverageContractTime}
          description={t('averageContractTime')}
          value={t('months', {
            months: Intl.NumberFormat(i18n.language, {
              style: 'decimal',
              maximumFractionDigits: 1,
            }).format(kpis?.avgContractTimeInMonths || 0),
          })}
        />
      ),
      mainDeals: <MainDeals deals={kpis?.mainDeals || []} />,
      rankingVehicles: <RankingVehicles data={kpis?.vehicleRanking || []} />,
      averageClosingTime: (
        <DealKPICard
          id="averageClosingTime"
          icon={iconAverageClosingTime}
          description={t('averageClosingTime')}
          value={t('days', {
            days: Intl.NumberFormat(i18n.language, {
              style: 'decimal',
              maximumFractionDigits: 1,
            }).format(kpis?.avgClosingTimeInDays || 0),
          })}
        />
      ),
      rankingPlans: <RankingPlans data={kpis?.planRanking || []} />,
    };

    return MAP_CARD_TO_COMPONENT[card];
  };

  useEffect(() => {
    if (container.current && enabled_swapy_dashboard.enabled) {
      swapy.current = createSwapy(container.current, {
        autoScrollOnDrag: true,
      });
    }

    swapy.current?.onSwap(event => {
      saveLayout(event.newSlotItemMap.asObject as Layout);
    });

    return () => swapy.current?.destroy();
  }, [saveLayout, enabled_swapy_dashboard]);

  useLayoutEffect(() => {
    window.HSStaticMethods.autoInit();
  }, []);

  const filtersBadges = useMemo(() => {
    const badges: string[] = [];

    if (storeSearch) {
      badges.push(storeSearch);
    }

    if (tagsSearch) {
      badges.push(tagsSearch);
    }

    if (sellerSearch) {
      badges.push(sellerSearch);
    }

    if (productSearch) {
      badges.push(productSearch);
    }

    if (locality) {
      badges.push(locality);
    }

    if (startDate && endDate) {
      const newStartDate = format(new Date(startDate), 'dd/MM/yyyy');
      const newEndDate = format(new Date(endDate), 'dd/MM/yyyy');

      if (differenceInDays(new Date(endDate), new Date(startDate)) >= 30) {
        badges.push(t('last30Days'));
      } else {
        badges.push(`${newStartDate} - ${newEndDate}`);
      }
    }

    return badges;
  }, [
    storeSearch,
    tagsSearch,
    sellerSearch,
    productSearch,
    startDate,
    endDate,
    locality,
    t,
  ]);

  return (
    <FormProvider {...hookForm}>
      <Helmet>
        <title>{t('title')} - QTool by IVECO</title>
      </Helmet>

      <FiltersHeader
        title={t('title')}
        breadcrumbs={[
          {
            title: t('welcomeUser', { name: person.socialName }),
          },
        ]}
        onClearFilters={handleClearFilters}
        onClearFreeSearch={handleClearFreeSearch}
        filters={filtersBadges}
        onRemoveFilter={handleRemoveFilter}
      >
        <div className="flex w-full flex-row items-center gap-1">
          {userType === 'ACCOUNT' && (
            <div>
              <Link target="_blank" to={links.chat}>
                <button type="button" className={style.buttonIA()}>
                  <span>{<AIIcon />}</span>
                  <span className={style.buttonIAText()}>
                    {t('common:talkToTheAi')}
                  </span>
                </button>
              </Link>
            </div>
          )}
          <div>
            <Button
              variant="secondary"
              leadingIcon={<Filter className="text-brand-pure" />}
              text={t('common:filters')}
              data-hs-overlay="#drawer-dashboard-filters"
              size="small"
            />
          </div>
        </div>
      </FiltersHeader>

      {isLoadingKPIs && <DashboardSkeleton />}

      {!isLoadingKPIs && (
        <div className={style.container()}>
          <div className={style.content()}>
            <main className={style.main()} ref={container}>
              <div className="grid w-full grid-cols-1 gap-4 xl:grid-cols-3">
                <div className={style.slot()} data-swapy-slot="1">
                  {getCardBySlot(layout['1'])}
                </div>

                <div className={style.slot()} data-swapy-slot="2">
                  {getCardBySlot(layout['2'])}
                </div>

                <div className={style.slot()} data-swapy-slot="3">
                  {getCardBySlot(layout['3'])}
                </div>
              </div>

              <div className="grid w-full grid-cols-1 gap-4 xl:grid-cols-3">
                <div className={style.slot()} data-swapy-slot="4">
                  {getCardBySlot(layout['4'])}
                </div>

                <div className={style.slot()} data-swapy-slot="5">
                  {getCardBySlot(layout['5'])}
                </div>

                <div className={style.slot()} data-swapy-slot="6">
                  {getCardBySlot(layout['6'])}
                </div>
              </div>

              <div className="grid w-full grid-cols-1 gap-4 xl:grid-cols-3">
                <div className={style.slot()} data-swapy-slot="7">
                  {getCardBySlot(layout['7'])}
                </div>

                <div className={style.slot()} data-swapy-slot="8">
                  {getCardBySlot(layout['8'])}
                </div>

                <div className="flex h-full w-full flex-col gap-4">
                  <div className={style.slot()} data-swapy-slot="9">
                    {getCardBySlot(layout['9'])}
                  </div>

                  <div className={style.slot()} data-swapy-slot="10">
                    {getCardBySlot(layout['10'])}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      )}

      <DrawerDashboardFilters />
    </FormProvider>
  );
}
