import { httpClient } from '@/config/httpClient';

type PostAccessBatchProps = {
  email: string;
  batchId: string;
};

export async function postAccessBatch({
  email,
  batchId,
}: PostAccessBatchProps) {
  return httpClient.post<void>('/batch/access-batch', {
    email,
    batchId,
  });
}
