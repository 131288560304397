import { cva } from 'class-variance-authority';

export const container = cva([
  'w-full h-full',
  'flex flex-col items-center',
  'rounded-2xl bg-white shadow-md',
  'relative',
  'data-[swapy-dragging]:border data-[swapy-dragging]:border-neutral-200 data-[swapy-dragging]:opacity-80',
]);

export const handle = cva(['absolute right-2 top-2 cursor-grab']);

export const handleIcon = cva(['h-4 w-4 text-white']);

export const titleContainer = cva([
  'flex justify-center gap-2',
  'w-full p-4 rounded-t-2xl',
  'bg-brand-pure',
]);

export const icon = cva(['text-white h-5 w-5']);

export const title = cva(['text-white text-sm font-normal']);

export const deals = cva([
  'h-full w-full max-h-[calc(100vh-572px)]',
  'overflow-y-auto',
  'flex flex-col gap-4 p-4 px-4',
]);

export const deal = cva(['flex gap-4 justify-between']);

export const dealer = cva(['flex items-baseline gap-2']);

export const dealerPosition = cva(['text-neutral-900 text-sm font-semibold']);

export const dealerInfo = cva(['flex flex-col items-start']);

export const storeName = cva(['text-neutral-900 text-sm font-semibold']);

export const subData = cva([
  'text-neutral-700 text-xs',
  'flex gap-2 items-center',
]);

export const values = cva(['flex flex-col items-end']);

export const total = cva(['text-neutral-900 text-xs']);

export const installment = cva(['text-neutral-900 text-xs']);
