import { httpClient } from '@/config/httpClient';

type PostActivateUserResponse = {
  newPasswordToken: string;
};

export async function postActivateUser(token: string) {
  return httpClient.post<PostActivateUserResponse>(
    '/user/activate-user',
    null,
    {
      headers: {
        'verify-token': token,
      },
    },
  );
}
