import { zodResolver } from '@hookform/resolvers/zod';
import { Mail, MoveLeft } from 'lucide-react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import QToolLogo from '@/assets/icons/qtool.svg';
import Button from '@/common/components/Button';
import { TextInput } from '@/common/components/TextInput';
import { useToast } from '@/common/hooks/useToast';
import { ENV } from '@/config/env';

import { useRecoveryPassword } from '../../api/useRecoveryPassword';
import { useLogin } from '../../hooks/useLogin';
import {
  ForgotPasswordForm,
  ForgotPasswordSchema,
} from '../../schemas/ForgotPasswordSchema';

import * as style from './styles';

export default function ForgotPassword() {
  const { toast } = useToast();
  const { t } = useTranslation(['login/forgotPassword', 'common']);
  const { signOut } = useLogin();
  const { recoveryPassword, isLoadingRecoveryPassword } = useRecoveryPassword();
  const hookForm = useForm<ForgotPasswordForm>({
    mode: 'onBlur',
    resolver: zodResolver(ForgotPasswordSchema),
  });

  const handleSubmitForgotPassword = async (data: ForgotPasswordForm) => {
    try {
      const payload = {
        email: data?.email,
      };

      await recoveryPassword(payload);

      toast.success(t(`${t('passwordRecoveryRequestProcessed')}.`));

      setTimeout(() => {
        signOut();
      }, 6000);
    } catch (error) {
      toast.error(`${t('unableToRecoveryPassword')}.`);
    }
  };

  return (
    <main className={style.main()}>
      <header className={style.header()}>
        <img src={QToolLogo} alt="QTool" className={style.logo()} />
      </header>

      <FormProvider {...hookForm}>
        <form
          className={style.form()}
          onSubmit={hookForm.handleSubmit(handleSubmitForgotPassword)}
        >
          <fieldset>
            <h1 className={style.title()}>{`${t('title')}?`}</h1>
            <h2 className={style.subTitle()}>{`${t('subTitle')}.`}</h2>
          </fieldset>

          <TextInput
            size="lg"
            name="email"
            placeholder={t('email')}
            LeadingIcon={<Mail className={style.icon()} />}
          />

          <Button
            type="submit"
            isLoading={isLoadingRecoveryPassword}
            text={t('recoveryPassword')}
            disabled={false}
            className={style.button()}
          />

          <Link to="/" className={style.link()}>
            <MoveLeft className={style.back()} />
            {t('backToLogin')}
          </Link>
        </form>
      </FormProvider>

      <footer className={style.footer()}>
        <a
          href={ENV.VITE_TERMS_OF_USE_URL}
          target="_bank"
          className={style.privacyPolicy()}
        >
          {t('common:termsOfUseAndPrivacyPolicy')}
        </a>

        <p className={style.copyright()}>
          &copy; Copyright By IVECO - {t('common:allRightsReserved')}
        </p>
      </footer>
    </main>
  );
}
