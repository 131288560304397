import { IAxiosError } from '@/@types/AxiosError';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useToast } from '@/common/hooks/useToast';

import { finishRequestApproval } from '../services/postFinishRequestApproval';

export function useFinishRequestApproval() {
  const { t } = useTranslation('invoicing/requestApprovalReview');
  const { toast } = useToast();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: finishRequestApproval,
    onError: (errorRequest: IAxiosError) => {
      toast.error(
        t('errorOnFinishRequestApproval'),
        errorRequest?.response?.data?.friendlyMessage,
        {
          toastId: 'finish-request-approval',
        },
      );
    },
  });

  return {
    finishRequestApproval: mutateAsync,
    isFinishingRequestApproval: isLoading,
  };
}
