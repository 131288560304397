import { GenreEnum } from '@/@types/Genre';
import { useTranslation } from 'react-i18next';

export function useTranslateGenre(): Record<GenreEnum, string> {
  const { t } = useTranslation('enums/genre');

  return {
    [GenreEnum.MALE]: t('male'),
    [GenreEnum.FEMALE]: t('female'),
    [GenreEnum.OTHER]: t('other'),
  };
}
