import { IAxiosError } from '@/@types/AxiosError';
import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@/common/hooks/useToast';
import { links } from '@/common/utils/links';

import { postValidateAccessBatch } from '../services/postValidateAccessBatch';

export function useValidateAccessBatch() {
  const { t } = useTranslation(['invoicing/requestApprovalValidateAccess']);
  const { toast } = useToast();
  const navigate = useNavigate();

  const { mutateAsync: validateAccessBatch, isLoading } = useMutation({
    mutationFn: postValidateAccessBatch,
    onSuccess: ({ data }, { batchId }) => {
      Cookies.set('jwt-access-batch', data);
      navigate(links.invoicing.requestApprovalReview(batchId));
    },
    onError: (error: IAxiosError) => {
      toast.error(
        t('errorOnValidateAccessBatch'),
        error?.response?.data?.friendlyMessage,
        {
          toastId: 'validate-access-batch-error',
        },
      );
    },
  });

  return {
    validateAccessBatch,
    isValidateAccessBatchLoading: isLoading,
  };
}
