import { IAxiosError } from '@/@types/AxiosError';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useToast } from '@/common/hooks/useToast';

import { postRejectRequestApproval } from '../services/postRejectRequestApproval';

export function useRejectRequestApproval() {
  const { t } = useTranslation('invoicing/requestApprovalReview');
  const { toast } = useToast();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: postRejectRequestApproval,
    onError: (errorRequest: IAxiosError) => {
      toast.error(
        t('errorOnRejectRequestApproval'),
        errorRequest?.response?.data?.friendlyMessage,
        {
          toastId: 'reject-request-approval',
        },
      );
    },
  });

  return {
    rejectRequestApproval: mutateAsync,
    isRejectingRequestApproval: isLoading,
  };
}
