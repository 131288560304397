import { FormPaymentEnum } from '@/@types/Contracts';
import { useTranslation } from 'react-i18next';

export function useTranslateFormPayment() {
  const { t } = useTranslation('enums/formPayment');

  return {
    [FormPaymentEnum.LINEAR]: t('linear'),
    [FormPaymentEnum.STAGED]: t('staged'),
  };
}
