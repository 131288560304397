import Cookies from 'js-cookie';

import { httpClient } from '@/config/httpClient';

import { RequestApprovalReviewForm } from '../schemas/RequestApprovalReviewSchema';

export const postRejectRequestApproval = async (
  data: RequestApprovalReviewForm & { batchId: string },
) => {
  return httpClient.post('/batch/approval-request/reject', data, {
    headers: {
      Authorization: `Bearer ${Cookies.get('jwt-access-batch')}`,
    },
  });
};
