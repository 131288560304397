import { cva } from 'class-variance-authority';

export const container = cva(['flex-grow', 'flex', 'flex-col', 'pt-4']);

export const horizontalDivider = cva([
  'w-full',
  'h-[1px]',
  'bg-neutral-200',
  'mb-4',
  'group-data-[collapsed=true]:w-12',
]);

export const content = cva(['space-y-2', 'hs-accordion-group']);

export const user = cva(
  [
    'flex',
    'items-center',
    'gap-3',
    'px-3',
    'py-2',
    'border',
    'border-neutral-200',
    'rounded-lg',
    'shadow-sm',
    'relative',
    'hover:bg-brand-100',
    'hover:border-brand-400',
    'group-data-[collapsed=true]:w-12',
  ],
  {
    variants: {
      isSelected: {
        true: ['!border-brand-400', 'bg-brand-50'],
      },
    },
  },
);

export const description = cva([
  'flex',
  'flex-col',
  'max-w-[8rem]',
  'group-data-[collapsed=true]:sr-only',
]);

export const profileChevronRight = cva([
  'absolute',
  'top-[21.5px]',
  'right-3',
  'text-brand-pure',
]);

export const name = cva([
  'font-inter',
  'text-sm',
  'text-neutral-700',
  'font-semibold',
  'leading-tight',
  'text-ellipsis',
  'whitespace-nowrap',
  'overflow-hidden',
]);

export const role = cva([
  'font-inter',
  'text-sm',
  'text-neutral-600',
  'font-light',
  'leading-tight',
]);

export const link = cva(
  [
    'flex',
    'items-center',
    'gap-4',
    'p-3.5',
    'text-sm',
    'font-inter',
    'rounded-md',
    'w-full',
    'h-full',
  ],
  {
    variants: {
      active: {
        true: ['bg-brand-gradient', 'text-brand-50'],
        false: [
          'text-neutral-700',
          'hover:bg-brand-50',
          'hover:text-brand-400',
        ],
      },
      danger: {
        true: [
          'hover:bg-driver-centricity-50',
          'hover:text-driver-centricity-600',
        ],
      },
      noAccess: {
        true: ['cursor-not-allowed', 'hover:bg-transparent'],
      },
    },
    defaultVariants: {
      active: false,
    },
  },
);

export const modalButton = cva([
  'flex',
  'items-center',
  'gap-x-3.5',
  'p-3.5',
  'rounded-md',
  'w-full',
  'group-data-[collapsed=true]:w-12',
  'text-sm',
  'font-inter',
  'truncate',
  'text-neutral-700',
  'hover:bg-brand-50',
  'hover:text-brand-400',
  'disabled:cursor-not-allowed',
  'disabled:hover:bg-transparent',
  'disabled:hover:text-neutral-700',
  'group',
]);

export const storesButtonTextContainer = cva([
  'group-data-[collapsed=true]:sr-only',
  'w-full',
  'flex',
  'justify-between',
  'items-center',
]);

export const storesButtonText = cva([
  'text-left',
  'w-[172px]',
  'text-ellipsis',
  'whitespace-nowrap',
  'overflow-hidden',
]);

export const storesButtonIcon = cva([
  'text-brand-400',
  'group-disabled:hidden',
]);

export const button = cva([
  'flex',
  'items-center',
  'gap-x-3.5',
  'p-3.5',
  'text-sm',
  'font-inter',
  'rounded-md',
  'w-full',
  'duration-700',
  'hover:bg-driver-centricity-50',
  'hover:text-driver-centricity-600',
  'group',
]);

export const buttonText = cva([
  'text-driver-centricity-800',
  'group-data-[collapsed=true]:sr-only',
]);

export const icon = cva([''], {
  variants: {
    isActive: {
      true: ['text-white'],
      false: ['text-brand-pure'],
    },
    noAccess: {
      true: ['text-neutral-400'],
    },
  },
  defaultVariants: {
    isActive: false,
  },
});

export const logoutIcon = cva(['text-driver-centricity-800']);

export const avatarSkeletonContainer = cva([
  'flex',
  'items-center',
  'my-6',
  'border-sky-100',
  'ml-2',
]);

export const avatarSkeleton = cva([
  'w-8',
  'h-8',
  'rounded-full',
  'bg-gray-200',
]);

export const textSkeletonContainer = cva(['ml-3', 'pt-2']);

export const nameSkeleton = cva([
  'w-28',
  'h-3',
  'bg-gray-200',
  'rounded-full',
  'mb-2',
]);

export const roleSkeleton = cva([
  'w-20',
  'h-3',
  'bg-gray-200',
  'rounded-full',
  'mb-2',
]);
