import { endOfDay } from 'date-fns';
import { Check, Filter, Search, X } from 'lucide-react';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Alert } from '@/common/components/Alert';
import Button from '@/common/components/Button';
import { DatePicker } from '@/common/components/DatePicker';
import { Drawer } from '@/common/components/Drawer';
import { TextInput } from '@/common/components/TextInput';
import { useToast } from '@/common/hooks/useToast';
import { useDashboardFilters } from '@/modules/panel/hooks/useDashboardFilters';
import { DashboardFilterForm } from '@/modules/panel/schemas/DashboardFilterSchema';

import * as styles from './styles';

export function DrawerDashboardFilters() {
  const { t } = useTranslation(['panel/panel', 'common']);
  const { toast } = useToast();
  const hookForm = useFormContext<DashboardFilterForm>();
  const { updateDashboardFilters: setDashboardFilters } = useDashboardFilters();

  const minYears = 2;

  const handleSetFilters = (data: DashboardFilterForm) => {
    setDashboardFilters(data);
    window.HSOverlay.close(
      document.getElementById('drawer-dashboard-filters')!,
    );
  };

  const handleCancelFilters = () => {
    hookForm.reset();
  };

  const startDate = hookForm.watch('startDate');
  const endDate = hookForm.watch('endDate');

  useEffect(() => {
    const todayNow = endOfDay(new Date()).getTime();
    const minDate = new Date().setFullYear(new Date().getFullYear() - 2);

    if (!startDate && endDate) {
      hookForm.setValue('endDate', '');
    }

    if (startDate) {
      if (new Date(startDate).getTime() > todayNow) {
        toast.error(
          t('common:invalidDate'),
          t('common:dateGreaterThanToday', { fieldDate: t('startDate') }),
        );
        hookForm.setValue('startDate', '');
        hookForm.setValue('endDate', '');
        return;
      }

      if (new Date(startDate).getTime() < minDate) {
        toast.error(
          t('common:invalidDate'),
          t('common:dateSmallerThanSpecificDate', {
            fieldDate: t('startDate'),
            otherDate: `${minYears} ${t('common:years')}`,
          }),
        );

        hookForm.setValue('startDate', '');
        hookForm.setValue('endDate', '');
        return;
      }
    }

    if (endDate) {
      if (new Date(endDate).getTime() > todayNow) {
        toast.error(
          t('common:invalidDate'),
          t('common:dateGreaterThanToday', { fieldDate: t('endDate') }),
        );

        hookForm.setValue('endDate', '');
        return;
      }
      if (
        startDate &&
        new Date(endDate).getTime() < new Date(startDate).getTime()
      ) {
        toast.error(
          t('common:invalidDate'),
          t('common:dateSmallerThanOtherDate', {
            fieldDate: t('endDate'),
            otherDate: t('startDate'),
          }),
        );

        hookForm.setValue('endDate', '');
        return;
      }
    }
  }, [startDate, endDate, t, toast, hookForm]);

  const yearsOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const maxYear = currentYear;
    const minYear = currentYear - minYears;

    const years = [];

    for (let year = maxYear; year >= minYear; year -= 1) {
      years.push({ label: year.toString(), value: year });
    }

    return years;
  }, []);

  return (
    <form onSubmit={hookForm.handleSubmit(handleSetFilters)}>
      <Drawer
        id="drawer-dashboard-filters"
        title={t('common:filters')}
        icon={Filter}
        rightActions={
          <>
            <Button
              variant="secondary"
              data-hs-overlay="#drawer-dashboard-filters"
              aria-controls="drawer"
              text={t('common:cancel')}
              type="button"
              leadingIcon={<X size={20} />}
              onClick={handleCancelFilters}
            />

            <Button
              variant="success"
              text={t('common:apply')}
              leadingIcon={<Check size={20} />}
              type="submit"
            />
          </>
        }
      >
        <input type="submit" className="hidden" />

        <div className={styles.WrapperInput()}>
          <TextInput
            size="sm"
            name="freeSearch"
            label={t('common:freeSearch')}
            placeholder={t('freeSearchPlaceholder')}
            LeadingIcon={
              <Search
                className={styles.SearchIcon()}
                size={18}
                strokeWidth={2.5}
              />
            }
          />

          <div className={styles.pickerWrapperInput()}>
            <DatePicker
              name="startDate"
              label={t('startDate')}
              placeholder={t('common:selectDate')}
              yearsOptions={yearsOptions}
            />

            <DatePicker
              name="endDate"
              label={t('endDate')}
              placeholder={t('common:selectDate')}
              yearsOptions={yearsOptions}
              disabled={!startDate}
            />
          </div>

          <Alert description={t('filterDate')} type="info" />

          <TextInput
            size="sm"
            name="tagsSearch"
            label={t('tagsSearch')}
            placeholder={t('tagsSearchPlaceholder')}
            LeadingIcon={
              <Search
                className={styles.SearchIcon()}
                size={18}
                strokeWidth={2.5}
              />
            }
          />

          <TextInput
            size="sm"
            name="storeSearch"
            label={t('storeSearch')}
            placeholder={t('storeSearchPlaceholder')}
            LeadingIcon={
              <Search
                className={styles.SearchIcon()}
                size={18}
                strokeWidth={2.5}
              />
            }
          />

          <TextInput
            size="sm"
            name="sellerSearch"
            label={t('sellerSearch')}
            placeholder={t('sellerSearchPlaceholder')}
            LeadingIcon={
              <Search
                className={styles.SearchIcon()}
                size={18}
                strokeWidth={2.5}
              />
            }
          />

          <TextInput
            size="sm"
            name="productSearch"
            label={t('productSearch')}
            placeholder={t('productSearchPlaceholder')}
            LeadingIcon={
              <Search
                className={styles.SearchIcon()}
                size={18}
                strokeWidth={2.5}
              />
            }
          />

          <TextInput
            size="sm"
            name="locality"
            label={t('locality')}
            placeholder={t('localityPlaceholder')}
            LeadingIcon={
              <Search
                className={styles.SearchIcon()}
                size={18}
                strokeWidth={2.5}
              />
            }
          />
        </div>
      </Drawer>
    </form>
  );
}
