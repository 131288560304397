import { create } from 'zustand';

import { IBatchDebitNotePaginated } from '../services/getPaginateBatchDebitNote';
import { IDebitNotePaginated } from '../services/getPaginateDebitNote';

interface ISelectedDebitNote {
  selectedDebitNote:
    | IBatchDebitNotePaginated['DebitNote']
    | IDebitNotePaginated
    | null;
  selectDebitNote: (selectedDebitNoteId: IDebitNotePaginated) => void;
}

export const useSelectedDebitNote = create<ISelectedDebitNote>(set => {
  return {
    selectedDebitNote: null,
    selectDebitNote(selectedDebitNote: IDebitNotePaginated) {
      set({ selectedDebitNote });
    },
  };
});
