import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '@/common/components/Button';
import { TextInput } from '@/common/components/TextInput';

import { useAccessBatch } from '../../hooks/useAccessBatch';
import {
  RequestApprovalAccessForm,
  RequestApprovalAccessSchema,
} from '../../schemas/RequestApprovalAccessSchema';

import * as style from './styles';

export default function RequestApproval() {
  const { t } = useTranslation('invoicing/requestApproval');
  const hookForm = useForm<RequestApprovalAccessForm>({
    resolver: zodResolver(RequestApprovalAccessSchema),
  });
  const { batchId } = useParams();
  const { accessBatch, isAccessingBatch } = useAccessBatch();

  const onSubmit = (data: RequestApprovalAccessForm) => {
    accessBatch({ ...data, batchId: batchId! });
  };

  return (
    <div className={style.container()}>
      <div className={style.info()}>
        <h1 className={style.title()}>{t('title')}</h1>

        <p className={style.description()}>
          {t('description')}
          <br />
          {t('subDescription')}
        </p>
      </div>

      <FormProvider {...hookForm}>
        <form
          className={style.form()}
          onSubmit={hookForm.handleSubmit(onSubmit)}
        >
          <TextInput name="email" placeholder={t('emailPlaceholder')} />

          <Button
            type="submit"
            text={t('access')}
            variant="primary"
            className="md:w-fit"
            isLoading={isAccessingBatch}
          />
        </form>
      </FormProvider>
    </div>
  );
}
