import { ICreateCustomerInDeal } from './Customer';
import { IDealProductPayload } from './Deal';
import { GenreEnum } from './Genre';

export enum SignStatusEnum {
  SKETCH = 'SKETCH',
  SENT = 'SENT',
  SIGNED = 'SIGNED',
  REVERSED = 'REVERSED',
  CANCELED = 'CANCELED',
}

export enum SignPersonStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum SignPersonPartEnum {
  COMPRADOR = 'COMPRADOR',
  VENDEDOR = 'VENDEDOR',
  TESTEMUNHA = 'TESTEMUNHA',
}

export enum EnvelopeStatusEnum {
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
  DENIED = 'DENIED',
}

export enum FormPaymentEnum {
  LINEAR = 'LINEAR',
  STAGED = 'STAGED',
}

export enum SalesModality {
  COMMERCIAL_CONCESSION = 'COMMERCIAL_CONCESSION',
  END_CUSTOMER = 'END_CUSTOMER',
}

export interface ICreateSign {
  contractualDraftTemplateId: string;
  storeId: string;
  dealId: string;
  userSellerId: string;
  customerId: string;
  customer?: ICreateCustomerInDeal | null;
  formPayment?: FormPaymentEnum;
  salesModality?: SalesModality;
  status?: SignStatusEnum;
  headquartersCode?: string | null;
  subsidiaryCode?: string | null;
  isDigitalSignature: boolean;
  urlDocument?: string | null;
  othersDocuments?: string[];
  signers?: ISignPersonPayload[];
  listChassis?: ISignChassis[];
}

export interface IGroupActivateContract {
  groupBillet: number;
  ids: string[];
  startDate: string;
  firstBillingDate: string;
}
export interface IActivateContract {
  signId: string;
  isSingleInstallment: boolean;
  groups: IGroupActivateContract[];
}

export interface IManageContractListChassis {
  codeContract: string;
  id: string;
}

export interface IManageContract {
  signId: string;
  externalCode: string | null;
  listChassis: IManageContractListChassis[];
}

export enum ContractSituation {
  SKETCH = 'SKETCH',
  PENDING = 'PENDING',
  ACTIVATING = 'ACTIVATING',
  ACTIVATED = 'ACTIVATED',
  FINALIZED = 'FINALIZED',
  CANCELLED = 'CANCELLED',
}

export interface ISignPayload {
  id: string;
  contractualDraftTemplateId: string;
  storeId: string;
  dealId: string;
  userSellerId: string;
  customerId: string;
  formPayment: FormPaymentEnum;
  headquartersCode?: string;
  subsidiaryCode?: string;
  isDigitalSignature: boolean;
  urlDocument?: string;
  salesModality?: SalesModality;
  othersDocuments?: string[];
  signers?: ISignPersonPayload[] | [];
  listChassis?: ISignChassis[] | [];
  status: SignStatusEnum;
  createdAt: string;
  updatedAt: string;
  isActive?: boolean;
  contractSituation: ContractSituation;
}

export type IEditSign = { accountId: string } & Partial<ICreateSign>;

export interface ISignPersonPayload {
  part: SignPersonPartEnum;
  name: string;
  documentNumber: string;
  email: string;
  identificationNumber?: string;
  identificationIssuer?: string;
  identificationIssueDate?: Date;
  birthDate?: Date;
  genre?: GenreEnum;
  phone?: string;
  envelopeStatus?: EnvelopeStatusEnum;
  occupation?: string;
  keysTagTemplate?: string[];
  status: SignPersonStatusEnum;
}

export enum AdjustmentEvery {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  NOT_APPLY = 'NOT_APPLY',
}

export enum SignChassisStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
}

export interface ISignChassis {
  id: string;
  dealProductId: string;
  chassis: string[];
  signId: string;
  codeContract: string;
  groupBillet: number;
  formPayment: FormPaymentEnum;
  startDate: string;
  endDate: string;
  firstBillingDate: string;
  nextAdjustmentDate: string;
  lastBillingDate: string;
  finishedAt: string | null;
  quantityInstallments: number;
  adjustmentEvery: AdjustmentEvery;
  initialMileage: number;
  finalMileage: number;
  totalMileageForTheContractPeriod: number;
  status: SignChassisStatus;
  metadata: Record<string, any>;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}
export interface ISignChassisPaginateContract {
  dealProductId: string;
  id: string;
  chassis: string;
  codeContract: string;
  status: SignStatusEnum;
  DealProduct: IDealProductPayload;
}

export type IReverseContract = {
  reasonOfTermination: string;
  isChurn: boolean;
  finishedAt: Date | null;
};

export type ICancelContract = {
  reasonOfTermination: string;
  finishedAt: Date | null | string;
};
