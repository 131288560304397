import { useInfiniteQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { formatCurrency } from '@/common/utils/formatCurrency';

import { getInfiniteBatchDebitNote } from '../services/getInfiniteBatchDebitNote';
import { IBatchDebitNotePaginated } from '../services/getPaginateBatchDebitNote';

import { useRecoverTaxes } from './useRecoverTaxes';
import { useTranslateDebitNoteStatus } from './useTranslateDebitNoteStatus';

export function useInfiniteBatchDebitNote(batchId?: string, search = '') {
  const [fetchedData, setFetchedData] = useState<IBatchDebitNotePaginated[]>(
    [],
  );
  const { recoverTaxes } = useRecoverTaxes();
  const { translateDebitNoteStatus } = useTranslateDebitNoteStatus();

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['infiniteBatchDebitNote', batchId],
    ({ pageParam }) => getInfiniteBatchDebitNote(batchId!, pageParam),
    {
      enabled: !!batchId,
      getNextPageParam: lastPage => {
        const nextPage = lastPage.data.meta.currentPage + 1;

        return nextPage <= lastPage.data.meta.totalPages ? nextPage : null;
      },
      getPreviousPageParam: firstPage => {
        const previousPage = firstPage.data.meta.currentPage - 1;

        return previousPage >= 1 ? previousPage : null;
      },
    },
  );

  const filterSearchedData = useCallback(
    (fetched: IBatchDebitNotePaginated[]) => {
      const searchLowerCase = search.toLowerCase();

      return fetched.filter(item => {
        const taxes = recoverTaxes(item.DebitNote);

        const propertiesToCompare = [
          item.DebitNote.numberDebitNote,
          format(new Date(item.DebitNote.issueDate), 'd/MM/yyyy'),
          format(new Date(item.DebitNote.dueDate), 'd/MM/yyyy'),
          item.DebitNote?.description,
          item.DebitNote?.Sign?.Deal?.Plan?.title,
          item.DebitNote?.debitType,
          formatCurrency(item.DebitNote.totalAmount),
          formatCurrency(item.DebitNote.netAmount),
          translateDebitNoteStatus(item.DebitNote.status),
        ];

        if (taxes?.cofinsAmount) {
          propertiesToCompare.push(formatCurrency(taxes.cofinsAmount));
        }

        if (taxes?.pisAmount) {
          propertiesToCompare.push(formatCurrency(taxes.pisAmount));
        }

        return propertiesToCompare.some(property =>
          property?.toLowerCase().includes(searchLowerCase),
        );
      });
    },
    [search, recoverTaxes, translateDebitNoteStatus],
  );

  useEffect(() => {
    if (data && search) {
      setFetchedData(prev =>
        filterSearchedData(
          [...prev, ...data.pages.flatMap(page => page.data.items)].filter(
            (register, index, self) =>
              index === self.findIndex(c => c.id === register.id),
          ),
        ),
      );
    }
  }, [data, filterSearchedData, search]);

  return {
    batchDebitNotes:
      search && search?.length
        ? fetchedData
        : data?.pages.flatMap(page => page.data.items),
    isLoadingBatchDebitNotes: isLoading,
    hasMoreDebitNotes: hasNextPage,
    hasPreviousDebitNotes: hasPreviousPage,
    isFetchingDebitNotes: isFetching,
    fetchMoreDebitNotes: fetchNextPage,
  };
}
