import { endOfDay, startOfDay } from 'date-fns';
import { z } from 'zod';

export const DashboardFilterSchema = z.object({
  freeSearch: z.string().optional(),
  storeSearch: z.string().optional(),
  tagsSearch: z.string().optional(),
  sellerSearch: z.string().optional(),
  productSearch: z.string().optional(),
  startDate: z
    .any()
    .optional()
    .nullable()
    .transform(date =>
      date ? startOfDay(new Date(date))?.toISOString() : null,
    ),
  endDate: z
    .any()
    .optional()
    .nullable()
    .transform(date => (date ? endOfDay(new Date(date))?.toISOString() : null)),
  locality: z.string().optional(),
});

export type DashboardFilterForm = z.infer<typeof DashboardFilterSchema>;
