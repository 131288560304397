import { cva } from 'class-variance-authority';

export const container = cva(['flex gap-x-3']);

export const input = cva(
  [
    'block rounded-md border-gray-200',
    'focus:scale-110 focus:border-blue-500 focus:ring-blue-500 focus:placeholder:text-transparent',
    'disabled:pointer-events-none disabled:opacity-50',
    '[&::-webkit-outer-spin-button]:appearance-none',
    'sm:text-sm text-center uppercase text-brand-pure font-semibold',
  ],
  {
    variants: {
      size: {
        sm: 'w-11 h-11',
        md: 'w-11 h-11 md:w-16 md:h-16',
        lg: 'w-20 h-20',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);
