import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet } from 'react-router-dom';

import { useUserStore } from '@/common/hooks/UserStore';

import {
  ContractProposalStepSchema,
  ContractProposalStepSchemaForm,
} from '../../schemas/ContractProposalStepSchema';

export function NewContractForm() {
  const { userAccount } = useUserStore();
  const contractProposalHookForm = useForm<ContractProposalStepSchemaForm>({
    mode: 'onChange',
    resolver: zodResolver(ContractProposalStepSchema),
    defaultValues: {
      isDigitalSignature: !!userAccount?.Account?.allowDigitalSignature,
    },
  });

  return (
    <FormProvider {...contractProposalHookForm}>
      <Outlet />
    </FormProvider>
  );
}
