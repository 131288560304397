import { useTranslation } from 'react-i18next';

import * as styles from './styles';

export function CardNoResults() {
  const { t } = useTranslation('common');

  return (
    <div className={styles.container()}>
      <p className={styles.title()}>{t('thereAreNoRecordsTitle')}</p>
      <p className={styles.subtitle()}>{t('thereAreNoRecordsSubTitle')}</p>
    </div>
  );
}
