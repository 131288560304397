import { cva } from 'class-variance-authority';

export const container = cva(['space-y-4 max-w-3xl mx-auto']);

export const content = cva([
  'flex flex-col items-center justify-center gap-16',
  'max-w-3xl mx-auto h-full p-8',
  'border border-brand-200 rounded-lg shadow-md shadow-brand-100',
]);

export const info = cva(['flex flex-col items-center justify-center gap-4']);

export const title = cva(['text-3xl font-bold text-center text-neutral-900']);

export const description = cva(['text-neutral-800 text-center text-lg']);

export const form = cva([
  'flex flex-col  items-center justify-center gap-4 self-center w-full max-w-md',
]);
