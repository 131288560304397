import { cva } from 'class-variance-authority';

export const container = cva([
  'select-none',
  'font-inter',
  'w-full',
  'md:pb-5',
  'pb-2',
  'flex',
  'flex-col',
  'items-start',
  'gap-2',
]);

export const heading = cva(['flex', 'gap-1', 'w-full', 'justify-between']);

export const title = cva([
  'sm:text-2xl',
  'text-xl',
  'md:text-3xl',
  'font-semibold',
  'text-brand-800',
]);

export const headerFooter = cva([
  'flex',
  'flex-col',
  'md:flex-row',
  'md:gap-5',
  'items-start',
  'md:items-center',
  'justify-between',
  'w-full',
]);

export const breadCrumbs = cva(['md:flex', 'gap-5', 'items-center', 'hidden']);

export const actions = cva(['flex', 'justify-center', 'items-center', 'gap-4']);

export const actionsWrapper = cva([
  'flex',
  'gap-4',
  'sm:items-end',
  'items-start',
  'mb-1',
]);

export const badgesContainer = cva(['grid', 'grid-cols-2', 'md:flex', 'gap-4']);
