import { useMutation } from '@tanstack/react-query';

import { ResetPasswordForm } from '../schemas/ResetPasswordSchema';
import { resetPasswordService } from '../services/resetPassword';

export function useResetPassword() {
  const {
    mutateAsync: mutateResetPasswordPassword,
    isLoading: isLoadingResetPasswordPassword,
  } = useMutation(resetPasswordService);

  const resetPassword = async (
    data: Omit<ResetPasswordForm, 'confirmPassword' | 'termsOfUse'>,
    verifyToken: string,
  ) => {
    await mutateResetPasswordPassword({
      ...data,
      verifyToken,
    });
  };

  return {
    resetPassword,
    isLoadingResetPasswordPassword,
  };
}
