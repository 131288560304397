import { cva } from 'class-variance-authority';

export const container = cva(['']);

export const tableData = cva([
  'px-6',
  'py-3',
  'whitespace-nowrap',
  'text-sm',
  'font-medium',
  'text-neutral-800',
]);

export const tableSubData = cva([
  'block',
  'text-sm',
  'text-neutral-500',
  'font-light',
]);
