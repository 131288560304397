import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Button from '@/common/components/Button';
import { Drawer } from '@/common/components/Drawer';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/common/components/Tabs';
import { useSelectedDebitNote } from '@/modules/invoicing/hooks/useSelectedDebitNote';

import { ChassisTable } from './components/ChassisTable';
import { GeneralData } from './components/GeneralData';
import * as style from './styles';

export function DrawerViewDebitNote() {
  const { t } = useTranslation('invoicing/openInvoices');
  const { selectedDebitNote } = useSelectedDebitNote();

  return (
    <Drawer
      id="drawer-view-debit-note"
      title={selectedDebitNote?.Customer.name}
      iconClickable
      withDivider={false}
      leftActions={
        <Button
          variant="secondary"
          text={t('common:close')}
          leadingIcon={<X size={20} />}
          data-hs-overlay="#drawer-view-debit-note"
        />
      }
    >
      <div className={style.content()}>
        <Tabs className={style.tabs()} defaultValue="generalData">
          <TabsList className={style.tabsList()}>
            <TabsTrigger value="generalData">{t('generalData')}</TabsTrigger>

            <TabsTrigger value="chassis">{t('chassis')}</TabsTrigger>
          </TabsList>

          <TabsContent value="generalData">
            <GeneralData />
          </TabsContent>

          <TabsContent value="chassis">
            <ChassisTable />
          </TabsContent>
        </Tabs>
      </div>
    </Drawer>
  );
}
