import { cva } from 'class-variance-authority';

export const Container = cva(
  [
    'hs-removing:translate-x-5',
    'hs-removing:opacity-0',
    'transition',
    'duration-300',
    'border',
    'rounded-md',
    'px-4',
    'py-3',
  ],
  {
    variants: {
      type: {
        success: ['bg-teal-50', 'border-teal-200'],
        error: ['bg-red-50', 'border-red-200'],
        warning: ['bg-yellow-50', 'border-yellow-200'],
        info: ['bg-blue-50', 'border-brand-300'],
      },
    },
  },
);

export const Content = cva(['flex', 'items-center']);

export const Icon = cva(['flex-shrink-0', 'self-baseline']);

export const MessageContainer = cva(['ml-3']);

export const Message = cva(['mb-1', 'text-sm', 'font-medium'], {
  variants: {
    type: {
      success: ['text-teal-800'],
      error: ['text-red-800'],
      warning: ['text-yellow-800'],
      info: ['text-brand-400'],
    },
  },
});

export const Description = cva(['text-sm', 'whitespace-pre-line'], {
  variants: {
    type: {
      success: ['text-teal-800'],
      error: ['text-red-800'],
      warning: ['text-yellow-800'],
      info: ['text-brand-400'],
    },
  },
});

export const DismissButton = cva(
  [
    'inline-flex',
    'bg-teal-50',
    'rounded-md',
    'p-1.5',
    'focus:outline-none',
    'focus:ring-2',
  ],
  {
    variants: {
      type: {
        success: [
          'bg-teal-50',
          'hover:bg-teal-100',
          'text-teal-500',
          'focus:ring-offset-2',
          'focus:ring-offset-teal-50',
          'focus:ring-teal-600',
        ],
        error: [
          'bg-red-50',
          'hover:bg-red-100',
          'text-red-500',
          'focus:ring-offset-red-50',
          'focus:ring-red-600',
        ],
        warning: [
          'bg-yellow-50',
          'hover:bg-yellow-100',
          'text-yellow-500',
          'focus:ring-offset-yellow-50',
          'focus:ring-yellow-600',
        ],
        info: [
          'bg-brand-100',
          'hover:bg-brand-200',
          'text-brand-500',
          'focus:ring-offset-brand-50',
          'focus:ring-brand-600',
        ],
      },
    },
  },
);

export const ICON_COLORS_BY_TYPE = {
  success: 'text-teal-400',
  error: 'text-red-400',
  warning: 'text-yellow-400',
  info: 'text-brand-400',
};
