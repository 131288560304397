import { UserStores } from '@/@types/Store';
import flagsmith from 'flagsmith';
import Cookies from 'js-cookie';
import { ArrowLeft, Info, LayoutGrid } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Alert } from '@/common/components/Alert';
import { StoreCard } from '@/common/components/StoreCard';
import { useAppRouter } from '@/common/hooks/useAppRouter';
import { useUserStore } from '@/common/hooks/UserStore';
import { links } from '@/common/utils/links';
import { ProtectedRoutes } from '@/routes/protected.routes';

import { useLogin } from '../../hooks/useLogin';

import * as style from './styles';

export default function StoreSelection() {
  const { t } = useTranslation(['login/storeSelection', 'common']);
  const { signOut } = useLogin();
  const navigate = useNavigate();
  const { saveUserStore, userStores, saveUserAccount } = useUserStore();
  const { changeRouter } = useAppRouter();

  const handleLoadDealerStore = async (selectedStore: UserStores) => {
    const {
      dealerId,
      id: storeId,
      role,
      dealerStoreUserId,
      Dealer,
    } = selectedStore;

    Cookies.set('dealerId', dealerId);
    Cookies.set('storeId', storeId);
    Cookies.set('role', role);
    Cookies.set('accountId', Dealer.Account.id);

    await flagsmith.setTrait('dealerId', dealerId);
    // USUÁRIOS "STORE" POSSUI A VARIÁVEL `storeUser` = `true`
    await flagsmith.setTrait('storeUser', !!dealerStoreUserId);
    await flagsmith.setTrait('role', role);

    saveUserAccount({
      accountId: Dealer.Account.id,
      role,
    });
    saveUserStore({
      dealerId,
      storeId,
      role,
    });
    changeRouter(ProtectedRoutes);
    navigate(links.panel);
  };

  return (
    <main className={style.container()}>
      <header className={style.header()}>
        <div className={style.titleContainer()}>
          <h1 className={style.title()}>{t('systemAccess')}</h1>

          <LayoutGrid className={style.icon()} />
        </div>

        <p className={style.subTitle()}>
          {t('selectCompany')} <strong className={style.nexpro()}>QTool</strong>
          .
        </p>
      </header>

      {userStores && userStores.length > 0 ? (
        <section className={style.section()}>
          <div className={style.sectionInfo()}>
            <h2 className={style.sectionTitle()}>{t('companies')}</h2>
            <p className={style.sectionSubTitle()}>{t('availableCompanies')}</p>
          </div>

          <ul className={style.list()}>
            {userStores?.map(store => (
              <li key={store.id}>
                <StoreCard
                  store={store}
                  onClick={() => handleLoadDealerStore(store)}
                />
              </li>
            ))}
          </ul>

          <div className={style.tipContainer()}>
            <Info className={style.icon()} />
            <span className={style.tip()}>{t('companyChangeTip')}</span>
          </div>
        </section>
      ) : (
        <section className={style.section()}>
          <Alert description={t('emptyStores')} type="warning" />
        </section>
      )}

      <footer className={style.footer()}>
        <div className={style.link()}>
          <ArrowLeft size={16} />
          <button type="button" onClick={signOut}>
            {t('backToLogin')}
          </button>
        </div>
      </footer>
    </main>
  );
}
