import { cva } from 'class-variance-authority';

export const container = cva([
  'py-3',
  'px-4',
  'bg-white',
  'rounded-t-lg',
  'border-b-2',
  'border-gray-200',
  'flex',
  'items-start',
  'justify-between',
  'gap-2',
  'flex-row',
  'lg:items-center',
]);

export const content = cva(['relative', 'w-full', 'lg:max-w-xs']);

export const actions = cva(['flex', 'items-center', 'gap-4']);

export const searchInputIcon = cva(['text-brand-pure']);

export const blueIcon = cva(['w-4', 'text-brand-pure']);

export const textDropDown = cva([
  'text-neutral-600',
  'text-sm',
  'hidden',
  'sm:flex',
  'md:text-base',
  'font-medium',
]);

export const dropButton = cva([
  'group',
  'flex',
  'items-center',
  'gap-3',
  'px-4',
  'py-3',
  'sm:py-2.5',
  'shadow',
  'border',
  'border-neutral-300',
  'rounded-md',
  'cursor-pointer',
  'hover:bg-gray-50',
  'min-w-fit',
]);

export const arrowDownDropDown = cva([
  'text-brand-pure',
  'group-data-[state=open]:hidden',
]);

export const arrowUpDropDown = cva([
  'text-brand-pure',
  'group-data-[state=closed]:hidden',
]);

export const description = cva(['flex', 'flex-col']);

export const dealer = cva(['text-base', 'text-neutral-800', 'font-semibold']);
