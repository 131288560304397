import { VariantProps } from 'class-variance-authority';
import { useFlags } from 'flagsmith/react';
import { GripVertical } from 'lucide-react';

import * as style from './styles';

type ContainerProps = VariantProps<typeof style.container>;

type DealKPICardProps = ContainerProps & {
  id: string;
  icon: string;
  description: string;
  value: string | number;
  extraInfo?: { label: string; value: string | number }[];
};

export function DealKPICard({
  id,
  direction,
  icon,
  description,
  value,
  extraInfo = [],
}: DealKPICardProps) {
  const { enabled_swapy_dashboard } = useFlags(['enabled_swapy_dashboard']);

  return (
    <div className={style.container({ direction })} data-swapy-item={id}>
      {enabled_swapy_dashboard.enabled && (
        <div data-swapy-handle className={style.handle()}>
          <GripVertical className={style.handleIcon()} />
        </div>
      )}

      <img src={icon} className={style.icon()} />

      <div className={style.content()}>
        <p className={style.description()}>{description}</p>

        <span className={style.value()}>{value}</span>

        {extraInfo.map(info => (
          <p key={info.label} className={style.extraInfo()}>
            {info.label} <span>{info.value}</span>
          </p>
        ))}
      </div>
    </div>
  );
}
