import flagsmith from 'flagsmith';
import Cookies from 'js-cookie';
import { ArrowLeft, LayoutGrid } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Alert } from '@/common/components/Alert';
import { CardApp } from '@/common/components/CardApp';
import { useAppRouter } from '@/common/hooks/useAppRouter';
import { SaveUserAccountProps, useUserStore } from '@/common/hooks/UserStore';
import { links } from '@/common/utils/links';
import { ProtectedRoutes } from '@/routes/protected.routes';

import { useLogin } from '../../hooks/useLogin';
import { useUserAccountsFilter } from '../../hooks/useUserAccountsFilter';

import { FiltersDropDown } from './components/FiltersDropDown';
import * as style from './styles';

export default function AccountSelection() {
  const { t } = useTranslation(['login/accountSelection', 'common']);
  const { signOut } = useLogin();
  const navigate = useNavigate();
  const { saveUserAccount, userAccounts } = useUserStore();
  const { country } = useUserAccountsFilter();
  const { changeRouter } = useAppRouter();

  const handleLoadUserAccount = async (
    selectedAccount: SaveUserAccountProps,
  ) => {
    const { accountId, role } = selectedAccount;

    Cookies.set('accountId', accountId);
    Cookies.set('role', role);

    await flagsmith.setTrait('role', role);

    saveUserAccount({
      accountId,
      role,
    });
    changeRouter(ProtectedRoutes);
    navigate(links.panel);
  };

  const accounts =
    userAccounts?.filter(account =>
      country ? account.Account.country === country : true,
    ) || [];

  return (
    <main className={style.container()}>
      <header className={style.header()}>
        <div className={style.titleContainer()}>
          <h1 className={style.title()}>{t('systemAccess')}</h1>

          <LayoutGrid className={style.icon()} />
        </div>

        <p className={style.subTitle()}>
          {t('selectCompany')} <strong className={style.nexpro()}>QTool</strong>
          .
        </p>
      </header>

      {accounts && accounts.length > 0 ? (
        <section className={style.section()}>
          <div className={style.sectionHeader()}>
            <div className={style.sectionInfo()}>
              <h2 className={style.sectionTitle()}>{t('companies')}</h2>
              <p className={style.sectionSubTitle()}>
                {t('availableCompanies')}
              </p>
            </div>

            <FiltersDropDown userAccounts={userAccounts!} />
          </div>

          <ul className={style.list()}>
            {accounts?.map(userAccount => (
              <li key={userAccount.accountId}>
                <CardApp
                  account={userAccount.Account}
                  onClick={() => handleLoadUserAccount(userAccount)}
                />
              </li>
            ))}
          </ul>

          <Alert
            description={t('companyChangeTip')}
            type="info"
            className="w-fit"
          />
        </section>
      ) : (
        <section className={style.section()}>
          <Alert description={t('emptyStores')} type="warning" />
        </section>
      )}

      <footer className={style.footer()}>
        <div className={style.link()}>
          <ArrowLeft size={16} />
          <button type="button" onClick={signOut}>
            {t('backToLogin')}
          </button>
        </div>
      </footer>
    </main>
  );
}
