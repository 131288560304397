import Cookies from 'js-cookie';

import { httpClient } from '@/config/httpClient';

export type FinishRequestApprovalProps = {
  batchId: string;
  comment: string;
};

export async function finishRequestApproval(data: FinishRequestApprovalProps) {
  return httpClient.post('/batch/compliance-request', data, {
    headers: {
      Authorization: `Bearer ${Cookies.get('jwt-access-batch')}`,
    },
  });
}
