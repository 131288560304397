import { useUserStore } from './UserStore';

export function useLabelFor() {
  const { userAccount } = useUserStore();
  const country = userAccount?.Account?.country || 'BR';

  const company: Record<string, string> = {
    BR: 'CNPJ',
    US: 'EIN',
    AR: 'CUIT',
    CO: 'NIT',
    IT: 'P.IVA',
    EC: 'RUC',
  };

  const person: Record<string, string> = {
    BR: 'CPF',
    US: 'SSN',
    AR: 'CUIT',
    CO: 'NIT',
    IT: 'Codice Fiscale',
    EC: 'RUC',
  };

  const companyOrPerson: Record<string, string> = {
    BR: 'CPF/CNPJ',
    US: 'SSN/EIN',
    AR: 'CUIT',
    CO: 'NIT',
    IT: 'P.IVA/Codice Fiscale',
    EC: 'RUC',
  };

  return {
    companyLabel: company[country],
    personLabel: person[country],
    companyOrPersonLabel: companyOrPerson[country],
  };
}
