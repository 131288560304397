import flagsmith from 'flagsmith';
import Cookies from 'js-cookie';

import { httpClient } from '@/config/httpClient';

export function logoutUserExpiredSession() {
  setTimeout(() => {
    flagsmith.logout();

    httpClient.defaults.headers.Authorization = '';

    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    Cookies.remove('sessionState');
    Cookies.remove('dealerId');
    Cookies.remove('accountId');
    Cookies.remove('storeId');
    Cookies.remove('role');
    Cookies.remove('userType');

    window.location.reload();
    window.location.href = '/';
  }, 1500);
}
