export function capitalizeString(input: string): string {
  if (input.length === 0) {
    return input;
  }

  const firstLetter = input[0].toUpperCase();
  const restOfString = input.slice(1).toLowerCase();

  return firstLetter + restOfString;
}
