import { createBrowserRouter } from 'react-router-dom';

import NotFoundComponent from '@/common/components/404';
import { ChatRoutes } from '@/modules/chat';
import { ClientsRoutes } from '@/modules/clients';
import { ContractsRoutes } from '@/modules/contract';
import { DealersRoutes } from '@/modules/dealers';
import { InvoicesRoutes } from '@/modules/invoices';
import { InvoicingRoutes } from '@/modules/invoicing';
import { InvoicingPublicRoutes } from '@/modules/invoicing/routes/public.routes';
import { PanelRoutes } from '@/modules/panel';
import { PricerRoutes } from '@/modules/pricer';
import { QuotesRoutes } from '@/modules/quotes';
import { ReportsRoutes } from '@/modules/reports';
import { SettingsRoutes } from '@/modules/settings';
import { StoresRoutes } from '@/modules/stores';
import { UserProfileRoutes } from '@/modules/userProfile';

export const ProtectedRoutes = createBrowserRouter([
  ...PanelRoutes,
  ...ChatRoutes,
  ...ContractsRoutes,
  ...ClientsRoutes,
  ...DealersRoutes,
  ...StoresRoutes,
  ...QuotesRoutes,
  ...ReportsRoutes,
  ...InvoicingRoutes,
  ...PricerRoutes,
  ...InvoicesRoutes,
  ...UserProfileRoutes,
  ...SettingsRoutes,
  ...InvoicingPublicRoutes,
  {
    path: '*',
    element: <NotFoundComponent />,
  },
]);
