import { DebitNoteStatus } from '@/@types/DebitNote';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useTranslateDebitNoteStatus } from '@/modules/invoicing/hooks/useTranslateDebitNoteStatus';

import * as styles from './styles';

type SituationBadgeProps = {
  status: DebitNoteStatus;
};

export function SituationBadge({ status }: SituationBadgeProps) {
  const { t } = useTranslation('common');
  const { translateDebitNoteStatus } = useTranslateDebitNoteStatus();

  const options = [
    {
      id: DebitNoteStatus.FIRST_INSTALLMENT,
      label: t('firstInstallment'),
      color: 'text-purple-600 bg-purple-100 ',
    },
    {
      id: DebitNoteStatus.LAST_INSTALLMENT,
      label: t('lastInstallment'),
      color: 'text-teal-600 bg-teal-100 ',
    },
    {
      id: DebitNoteStatus.REGULAR,
      label: t('regular'),
      color: 'text-green-600 bg-green-100 ',
    },
    {
      id: DebitNoteStatus.CANCELLED,
      label: t('cancelled'),
      color: 'text-red-600 bg-red-100 ',
    },
  ];

  const optionSelected = options.find(option => option.id === status);

  return (
    <div className={clsx(styles.container(), optionSelected?.color)}>
      {translateDebitNoteStatus(status)}
    </div>
  );
}
