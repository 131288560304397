import { useTranslation } from 'react-i18next';

import * as styles from './styles';

export function TableHead() {
  const { t } = useTranslation(['invoicing/openInvoices', 'common']);

  const columns = [
    {
      field: 'chassi',
      label: t('chassi'),
    },
    {
      field: 'installment',
      label: t('installment'),
    },
    {
      field: 'km',
      label: t('km'),
    },
    {
      field: 'value',
      label: t('value'),
    },
  ];

  return (
    <thead className={styles.tableHead()}>
      <tr>
        {columns.map((column, index) => (
          <th
            key={column.field}
            scope="col"
            className={styles.column({ position: index })}
          >
            {column.label}
          </th>
        ))}
      </tr>
    </thead>
  );
}
