import { useMutation } from '@tanstack/react-query';

import { ForgotPasswordForm } from '../schemas/ForgotPasswordSchema';
import { postRecoveryPasswordService } from '../services/postRecoveryPassword';

export function useRecoveryPassword() {
  const {
    mutateAsync: mutateRecoveryPassword,
    isLoading: isLoadingRecoveryPassword,
  } = useMutation(postRecoveryPasswordService);

  const recoveryPassword = async (data: ForgotPasswordForm) => {
    await mutateRecoveryPassword({
      ...data,
    });
  };

  return {
    recoveryPassword,
    isLoadingRecoveryPassword,
  };
}
