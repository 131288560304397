import { IAxiosError } from '@/@types/AxiosError';
import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@/common/hooks/useToast';
import { links } from '@/common/utils/links';

import { postAccessBatch } from '../services/postAccessBatch';

export function useAccessBatch() {
  const { t } = useTranslation('invoicing/requestApprovalValidateAccess');
  const { toast } = useToast();
  const navigate = useNavigate();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: postAccessBatch,
    onSuccess: (_, { batchId, email }) => {
      navigate(links.invoicing.requestApprovalValidateAccess(batchId));
      Cookies.set('email-access-batch', email);
    },
    onError: (errorRequest: IAxiosError) => {
      toast.error(
        t('errorOnValidateAccessBatch'),
        errorRequest?.response?.data?.friendlyMessage,
        {
          toastId: 'validate-access-batch-error',
        },
      );
    },
  });

  return {
    accessBatch: mutateAsync,
    isAccessingBatch: isLoading,
    errorOnAccessBatch: error,
  };
}
