import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import QToolLogo from '@/assets/icons/qtool.svg';

import * as style from './styles';

export function RequestApprovalLayout() {
  const { t } = useTranslation('invoicing/requestApproval');
  return (
    <div className={style.container()}>
      <Helmet>
        <title>{t('titleHeader')} - QTool by IVECO</title>
      </Helmet>

      <header>
        <img src={QToolLogo} alt="QTool by IVECO" />
      </header>

      <main className={style.main()}>
        <Outlet />
      </main>
    </div>
  );
}
