import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { RootLayout } from '@/layouts/Root';

const Invoice = lazy(() => import('@/modules/invoices/pages/Invoices'));

export const InvoicesRoutes: RouteObject[] = [
  {
    element: <RootLayout />,
    children: [
      {
        path: links.invoices.invoices,
        element: <Invoice />,
      },
    ],
  },
];
